import axios, { isAxiosError } from 'axios'
import { ref, toRaw, reactive } from 'vue'
import { useCampaignStore } from '@/stores/campaigns'
import { type Campaign } from '@/types/campaign'
import { type CampaignQuestions } from '@/types/campaign-questions'
import { type Variables } from '@/types/variables'
import { type Payment } from '@/types/payment'
import useHelpers from './useHelpers'
import type { Agent } from '@/types/agent'



export default function useCampaigns() {
  const apiBaseUrl = import.meta.env.VITE_API_URL
  const campaignStore = useCampaignStore()
  const campaignDetails = ref<Campaign>()
  const applicationQuestions = ref<CampaignQuestions[]>([])
  const PbStats = ref()
  const campaignVariables = ref<Variables[]>([])
  const campaignPayroll = ref<Payment[]>([])
  const { errorHelper } = useHelpers()
  const currentCampaignAgents = ref<Agent[]>([])
  const currentCampaignApplications = ref()
  const campaignProperties = reactive({
    name: false,
    description: false,
    training_bonus: false,
    min_hourly_pay: false,
    max_hourly_pay: false,
    shift_days: false,
    shift_hours: false,
    lms_url: false,
    content_blocks: false,
    certification_progress: false
  })
  const serialize = (obj: any) => {
    const str = []
    for (const p in obj)
      if (obj[p] != null) {
        if (p === 'agent_status_id') {
          for (const v of obj[p]) {
            str.push(p + '=' + v)
          }
        } else {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
      }
    return str.join('&')
  }


  async function showHideActiveCampaignContent(status: string) {
    switch (status) {
      case 'OptIn':
      case 'OptIn - Waitlist': {
        campaignProperties['name'] = true
        campaignProperties['description'] = true
        campaignProperties['training_bonus'] = true
        campaignProperties['min_hourly_pay'] = true
        campaignProperties['max_hourly_pay'] = true
        campaignProperties['shift_days'] = true
        campaignProperties['shift_hours'] = true
        campaignProperties['lms_url'] = false
        campaignProperties['content_blocks'] = true
        campaignProperties['certification_progress'] = false
        break
      }
      case 'Pre-Roster':
      case 'Pre-Roster - Phase 1 Training':
      case 'Production':
      case 'Remediation': {
        campaignProperties['name'] = true
        campaignProperties['description'] = true
        campaignProperties['training_bonus'] = true
        campaignProperties['min_hourly_pay'] = true
        campaignProperties['max_hourly_pay'] = true
        campaignProperties['shift_days'] = true
        campaignProperties['shift_hours'] = true
        campaignProperties['lms_url'] = true
        campaignProperties['content_blocks'] = true
        campaignProperties['certification_progress'] = true
        break
      }

      case 'Roster - Credentials Requested':
      case 'Roster - Training In-Progress':
      case 'Roster - Phase 2 Training':
      case 'Nesting - Modules Complete':
      case 'Nesting - Final Exam Complete':
      case 'Nesting - Navigation Meeting':
      case 'Nesting - First Call': {
        campaignProperties['name'] = true
        campaignProperties['description'] = true
        campaignProperties['training_bonus'] = true
        campaignProperties['min_hourly_pay'] = true
        campaignProperties['max_hourly_pay'] = true
        campaignProperties['shift_days'] = true
        campaignProperties['shift_hours'] = true
        campaignProperties['lms_url'] = true
        campaignProperties['content_blocks'] = true
        campaignProperties['certification_progress'] = false
        break
      }
      // TODO: Cleanup; All removed statuses should not view any active campaigns content
      case 'Removed':
      case 'Removed - Training Stale':
      case 'Removed - Production Stale':
      case 'Removed - Resigned':
      case 'Removed - Performance':
      case 'Removed - ShyftOff Removal': {
        campaignProperties['name'] = false
        campaignProperties['description'] = false
        campaignProperties['training_bonus'] = false
        campaignProperties['min_hourly_pay'] = false
        campaignProperties['max_hourly_pay'] = false
        campaignProperties['shift_days'] = false
        campaignProperties['shift_hours'] = false
        campaignProperties['lms_url'] = false
        campaignProperties['content_blocks'] = false
        campaignProperties['certification_progress'] = false
        break

      }
    }
  }

  async function getUserCampaignApplications() {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/applications`)
      campaignStore.setCampaignApplications(JSON.parse(JSON.stringify(data)))
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getCampaignApplicationQuestions(id: number) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/campaigns/${id}/application-questions`)
      applicationQuestions.value = data
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function applyToCampaign(payload: { policyAgreement: boolean; id: number, answers: any[]}) {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/users/me/applications`, {
        has_complied_with_policy_agreement: payload.policyAgreement,
        campaign_id: payload.id,
        campaign_specific_answers: payload.answers
      })
      return response.data
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function sendResume(payload: { file: File; id: number;}) {
    try {
      const form = new FormData()
      form.append('file', payload.file)
      await axios.put(`${apiBaseUrl}/api/users/me/applications/${payload.id}/resume`, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getPBStats({id, limit}: {id: number, limit: number | null}) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/campaigns/${id}/pb-stats`, {
        params: {
          limit: limit
        }
      })
      PbStats.value = data
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getUserCampaigns(payload: { offset?: number|null; limit?: number; with_count?: boolean, is_active?: boolean|null, is_in?: boolean|null }) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/campaigns`, {
        params: {
          offset: payload.offset ?? null,
          limit: payload.limit ?? 12,
          with_count: payload.with_count ?? true,
          is_active: payload.is_active ?? null,
          is_in: payload.is_in ?? null,
        }
      })
      // if is_active filter present then only set the active campaign list
      if (payload.is_active) {
        // const filteredData = toRaw(data.results).filter((campaign: Campaign) => campaign.agent_status?.status_group !== 1)
        // campaignStore.setActiveCampaigns(filteredData)
        campaignStore.setActiveCampaigns(toRaw(data.results))
        campaignStore.setActiveCampaignCount(data.count)
      } else {
        campaignStore.setCampaigns(toRaw(data.results))
        campaignStore.setCampaignCount(data.count)
      }
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchCampaign(id: number) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/campaigns/${id}`)
      return data
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchAllCampaigns(payload: { offset?: number; limit?: number; with_count?: boolean, is_active?: boolean }) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/campaigns`, {
        params: {
          is_active: payload.is_active ?? false,
          offset: payload.offset ?? 0,
          limit: payload.limit ?? 12,
          with_count: payload.with_count ?? true
        }
      })
      campaignStore.setCampaigns(JSON.parse(JSON.stringify(data.results)))
      campaignStore.setCampaignCount(data.count)
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getAgentCampaignDetails(id: string | number) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/campaigns/${typeof id === 'string' ? parseInt(id): id}`)
      campaignDetails.value = data
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getCampaignVariables(id: string | number) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/campaigns/${id}/variables`)
      campaignVariables.value = data
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getCampaignPayroll(id: string | number) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/payments`, {
        params: {
          campaign_id: id
        }
      })
      campaignPayroll.value = data
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchCampaignAgents(payload: { id: number; offset?: number; limit?: number; qualification_ids?: string; agent_status_id?: number[]; with_count?: boolean}) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/campaigns/${payload.id}/agents`, {
        params: {
          offset: payload.offset ?? null,
          limit: payload.limit ?? 3000,
          qualification_ids: payload.qualification_ids ?? null,
          agent_status_id: payload.agent_status_id,
          with_count: payload.with_count ?? null
        },
        paramsSerializer: (params: any) => {
          // Build the query string
          return serialize(params)
        }
      })
      if (data?.length) {
        currentCampaignAgents.value = data.sort((a: any, b: any) => {
          const dateA: any = new Date(a.campaign_status_updated_at)
          const dateB: any = new Date(b.campaign_status_updated_at)

          return dateA - dateB
        })
      } else {
        currentCampaignAgents.value = []
      }

    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function fetchCampaignApplications(payload: { id: number; offset?: number; limit?: number; }) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/campaigns/${payload.id}/applications`, {
        params: {
          offset: payload.offset ?? null,
          limit: payload.limit ?? 3000,
        }
      })

      currentCampaignApplications.value = data
    } catch (error: any) {
     if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function saveCampaignContentBlocks(id: number, content: string) {
    try {
      const { data } = await axios.patch(`${apiBaseUrl}/api/campaigns/${id}`, {
        wysiwyg_description: content
      })
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchCampaignDetails(id: number) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/campaigns/${id}`)
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  return {
    getUserCampaignApplications,
    getCampaignApplicationQuestions,
    getUserCampaigns,
    fetchAllCampaigns,
    getAgentCampaignDetails,
    campaignDetails,
    applyToCampaign,
    sendResume,
    getPBStats,
    applicationQuestions,
    PbStats,
    getCampaignVariables,
    campaignVariables,
    getCampaignPayroll,
    campaignPayroll,
    fetchCampaignAgents,
    currentCampaignAgents,
    fetchCampaignApplications,
    currentCampaignApplications,
    saveCampaignContentBlocks,
    campaignProperties,
    showHideActiveCampaignContent,
    fetchCampaign,
    fetchCampaignDetails
  }
}
