
<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
const { params }: any = useRoute()
const props = defineProps(['searchText'])

const showAnswer = ref<number[]>([])
const currentChapter = computed(() => {
  return params.chapter
})

function clickedQuestion(n: number) {
  if (!showAnswer.value.includes(n)) {
    showAnswer.value.push(n)
  } else {
    showAnswer.value = showAnswer.value.filter((s:number) => s !== n)
  }
}

const FaqHTMLNodes = ref()
const showElements = ref<number[]>([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25])

onMounted(() => {
  FaqHTMLNodes.value = document.querySelectorAll('.tw-cursor-pointer.tw-pt-8')
})
watch(() => props.searchText, (newVal) => {
  const visibleElements = []
  for (let index = 0; index < FaqHTMLNodes.value.length; index++) {
    if (FaqHTMLNodes.value[index].innerHTML.includes(newVal)) {
      visibleElements.push(index)
    }
  }
  showElements.value = visibleElements
})
</script>
<template>
  <div class="tw-col-span-12 md:tw-col-span-8 xl:tw-col-span-9">
    <div v-show="currentChapter === 'section1' || props.searchText != null" >
      <div v-if="showElements.includes(0)" @click="clickedQuestion(0)" class="tw-cursor-pointer tw-pt-8 lg:tw-pt-11">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8">
          <svg v-if="!showAnswer.includes(0)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(0)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(0)}">
            Where can I find the ShyftOff System & Equipment Policy?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(0)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(0)}">
            <p>Computer Specs:</p>
            <ul>
              <li>15 GHz dual-core processor or better</li>
              <li>4GB of RAM or better</li>
              <li>Windows 8.1(64Bit) or newer with current OS patches (last 15 days)</li>
            </ul>
            <p>Internet Specs:</p>
            <ul>
              <li>Download speed of 5 Mbps or better</li>
              <li>Upload speed of 3 Mbps or better</li>
              <li>Upload latency of 150 ms or better</li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(1)" @click="clickedQuestion(1)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(1)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(1)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(1)}">
            I have a grievance who do I contact?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(1)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(1)}">
            <p>Please contact HR by creating a support ticket in the Agent Portal.</p>
            <p>Please allow up to 48 business hours for us to respond to your questios.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(2)" @click="clickedQuestion(2)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(2)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(2)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(2)}">
            What is the pay?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(2)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(2)}">
            <p>Each campaign will be unique, and you will have the chance to review the payment terms before signing up.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(3)"  @click="clickedQuestion(3)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(3)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(3)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(3)}">
            I have technical support questions who do I contact?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(3)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(3)}">
            <p>Please contact <a href="mailto:tech@shyftoff.com">tech@shyftoff.com</a> with technical questions.</p>
            <p>Please allow up to 48 business hours for us to respond to email questions.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(4)" @click="clickedQuestion(4)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(4)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(4)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(4)}">
            Do I get benefits such as health insurance?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(4)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(4)}">
            <p>As a 1099 contractor, you are not eligible for health benefits through our company. However, a variety of affordable health care plans are available through <a href="www.healthcare.gov" target="_blank">www.healthcare.gov</a>.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(5)" @click="clickedQuestion(5)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(5)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(5)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(5)}">
            I need verification of employment, who do I ask?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(5)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(5)}">
            <p>Please contact HR by creating a support ticket in the Agent Portal.</p>
            <p>Please allow up to 48 business hours for us to respond to your questios.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(6)" @click="clickedQuestion(6)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(6)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(6)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(6)}">
            What kind of incentives do you offer?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(6)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(6)}">
            <p>We offer a variety of incentives for our employees, including performance-based bonuses, and company-wide recognition programs.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(7)" @click="clickedQuestion(7)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(7)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(7)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(7)}">
            How can I change my profile information?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(7)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(7)}">
            <p>Please contact HR by creating a support ticket in the Agent Portal.</p>
            <p>Please allow up to 48 business hours for us to respond to your questions.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(8)" @click="clickedQuestion(8)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(8)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(8)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(8)}">
            When can I start scheduling shifts?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(8)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(8)}">
            <p>Thank you for your interest in joining our team. To ensure the safety and quality of service we provide to our clients, we require our team members to be certified and have the necessary credentials before starting to schedule shifts.</p>
            <p>Once you have obtained the required certification and credentials, we will be happy to grant access to our scheduling platform so you can begin to schedule your shifts.</p>
          </div>
        </div>
      </div>
    </div>
    <div v-show="currentChapter === 'section2' || props.searchText != null">
      <div v-if="showElements.includes(9)" @click="clickedQuestion(9)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(9)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(9)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(9)}">
            When is pay day?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(9)}">
          <div class="tw-overflow-hidden tw-pr-6 tw-border-b-2 tw-border-solid tw-border-[#EBEBEB]" :class="{'tw-pb-11': showAnswer.includes(9)}">
            <p>Most campaigns will payout weekly on Fridays.</p>
            <p>There will be exceptions to this though where the pay period could be every two weeks, and this would be noted in the campaign description.</p>
            <p>We pay contractors via direct deposit through Gusto, which you will be invited to once you start on a campaign.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(10)" @click="clickedQuestion(10)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(10)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(10)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(10)}">
            How do I opt into a campaign?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(10)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(10)}">
            <p>Click on "Campaigns".</p>
            <p>Find the campaign you are interested in and click on it.</p>
            <p>Review the details and requirment for the campaign.</p>
            <p>Fill out the Opt-In form and submit it.</p>
            <p>Further instructions will be emailed to you once your requrest has been reviewed.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(11)" @click="clickedQuestion(11)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(11)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(11)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(11)}">
            Where do I find a list of the open campaigns?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(11)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(11)}">
            <p>To view our open campaigns, please log in to the Agent Portal and click on the "Open Campaigns" tab. This will provide you with a list of all the campaigns that are currently open and available for you to apply.</p>
            <p>The Agent Portal is designed to provide you with all the necessary information related to the campaigns, including campaign details, requirements, and compensation. You can also view your application status and communicate with our team through the portal.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(12)" @click="clickedQuestion(12)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(12)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(12)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(12)}">
            Will I be asked to complete a video interview for each campaign I opt into?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(12)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(12)}">
            <p>Yes. Each campaign will require individuals to complete a video interview and will be designed to identify the most qualified applicants</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(13)" @click="clickedQuestion(13)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(13)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(13)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(13)}">
            Will I be asked to complete a video interview for each campaign I opt into?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(13)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(13)}">
            <p>Yes. Each campaign will require individuals to complete a video interview and will be designed to identify the most qualified applicants</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(14)" @click="clickedQuestion(14)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(14)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(14)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(14)}">
            I am already working on one campaign, but I would like to work on another campaign. Is this possible?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(14)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(14)}">
            <p>Yes, it is possible to work multiple campaigns. In fact, we welcome our contractors to work on multiple campaigns. We believe that this will help you to develop your skills and broaden your experience with us. If you're interested in working on another campaign, please check out the open campaigns in the Agent Portal.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(15)" @click="clickedQuestion(15)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(15)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(15)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(15)}">
            How do I get paid?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(15)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(15)}">
            <p>Most campaigns pay you for the time you are productive. Productive time includes talk time, after-call work (ACW), and time available waiting for the next call to come in.</p>
            <p>On each campaign, the process of getting logged in and into a productive state will be made very clear so there is no confusion.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(16)" @click="clickedQuestion(16)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(16)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(16)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(16)}">
            Are there any restrictions on the incentives offered?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(16)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(16)}">
            <p>Yes. All incentives are based on productive hours or specific client metrics.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(17)" @click="clickedQuestion(17)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(17)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(17)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(17)}">
            When do I get access to When I Work?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(17)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(17)}">
            <p>Once you have successfully completed the training, we will add you to our scheduling platform, When I Work. This platform will allow you to access available shifts, view shifts you have grabbed, and request to drop/release a shift you scheduled.</p>
            <p>We will send you an invitation email with instructions on how to access the platform and set up your account. Please make sure to follow the instructions provided in the email to complete the registration process.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(18)" @click="clickedQuestion(18)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(18)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(18)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(18)}">
            When will more campaigns be posted?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(18)}">
          <div class="tw-overflow-hidden tw-border-b-2 tw-border-solid tw-border-[#EBEBEB] tw-pr-6" :class="{'tw-pb-11': showAnswer.includes(18)}">
            <p>We are constantly working to offer more campaigns and hours.</p>
            <p>Many of our partners are seasonal, which can cause fluctuations in how many shifts are available.</p>
            <p>Navigate to the Campaigns tab to view our active campaigns and learn how to become eligible for them.</p>
            <p>Campaigns may require certifications based on their industry or complexity.</p>
          </div>
        </div>
      </div>
    </div>
    <div v-show="currentChapter === 'section3' || props.searchText != null">
      <div v-if="showElements.includes(19)" @click="clickedQuestion(19)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(19)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(19)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(19)}">
            When do I get paid for the training bonus?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(19)}">
          <div class="tw-overflow-hidden tw-pr-6 tw-border-b-2 tw-border-solid tw-border-[#EBEBEB]" :class="{'tw-pb-11': showAnswer.includes(19)}">
            <p>Each campaign will be unique, and you will have the chance to review the training bonus payout terms before signing up.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(20)" @click="clickedQuestion(20)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(20)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(20)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(20)}">
            How do I qualify for a performance-based bonus?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(20)}">
          <div class="tw-overflow-hidden tw-pr-6 tw-border-b-2 tw-border-solid tw-border-[#EBEBEB]" :class="{'tw-pb-11': showAnswer.includes(20)}">
            <p>Performance-based bonuses are awarded based on a variety of factors. Your leadership team will provide you with more information about the specific criteria for earning a bonus.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(21)" @click="clickedQuestion(21)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(21)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(21)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(21)}">
            How do I report an issue with my pay?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(21)}">
          <div class="tw-overflow-hidden tw-pr-6 tw-border-b-2 tw-border-solid tw-border-[#EBEBEB]" :class="{'tw-pb-11': showAnswer.includes(21)}">
            <p>Please contact HR by creating a Support Ticket in the ShyftOff App.</p>
            <p>Select your profile icon on the bottom left hand side of your screen and select Support.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(22)" @click="clickedQuestion(22)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(22)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(22)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(22)}">
            When can I begin training?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(22)}">
          <div class="tw-overflow-hidden tw-pr-6 tw-border-b-2 tw-border-solid tw-border-[#EBEBEB]" :class="{'tw-pb-11': showAnswer.includes(22)}">
            <p>Thank you for your interest in our campaign. We're excited to have you as a potential team member! Please note that training for the campaign will begin only after you have been selected for the campaign.</p>
            <p>Our team will review all the applications received and select candidates based on their qualifications, experience, and performance during the interview process. If you are selected, we will notify you and provide you with all the necessary details regarding the campaign and the training program.</p>
            <p>Once you have been selected for the campaign, we will grant access to the training materials. The training is designed to provide you with the knowledge and skills required to perform your duties effectively during the campaign.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(23)" @click="clickedQuestion(23)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(23)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(23)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(23)}">
            What is my Agent ID/User ID?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(23)}">
          <div class="tw-overflow-hidden tw-pr-6 tw-border-b-2 tw-border-solid tw-border-[#EBEBEB]" :class="{'tw-pb-11': showAnswer.includes(23)}">
            <p>Your unique ShyftOff ID is for identification purposes. You can find your ShyftOff ID by logging into the Agent Portal and clicking on the "General Info" tab. Your agent ID will be displayed in this section.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(24)" @click="clickedQuestion(24)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(24)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(24)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(24)}">
            Do I qualify for FMLA or short/long term disability?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(24)}">
          <div class="tw-overflow-hidden tw-pr-6 tw-border-b-2 tw-border-solid tw-border-[#EBEBEB]" :class="{'tw-pb-11': showAnswer.includes(24)}">
            <p>As a 1099 contractor, you are not eligible for FMLA or short/long term disability benefits through our company. However, short and long term disability insurance is available privately through a myriad of commercial sources.</p>
          </div>
        </div>
      </div>
      <div v-if="showElements.includes(25)" @click="clickedQuestion(25)" class="tw-cursor-pointer tw-pt-8">
        <div class="tw-flex tw-items-center tw-space-x-6 tw-justify-start tw-pb-8 ">
          <svg v-if="!showAnswer.includes(25)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM13 24C10.8244 24 8.69767 23.3549 6.88873 22.1462C5.07979 20.9375 3.66989 19.2195 2.83733 17.2095C2.00477 15.1995 1.78693 12.9878 2.21137 10.854C2.63581 8.72022 3.68345 6.7602 5.22183 5.22183C6.76021 3.68345 8.72022 2.6358 10.854 2.21136C12.9878 1.78692 15.1995 2.00476 17.2095 2.83733C19.2195 3.66989 20.9375 5.07979 22.1462 6.88873C23.3549 8.69767 24 10.8244 24 13C23.9967 15.9164 22.8367 18.7123 20.7745 20.7745C18.7123 22.8367 15.9164 23.9967 13 24ZM19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14H14V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19C12.7348 19 12.4804 18.8946 12.2929 18.7071C12.1054 18.5196 12 18.2652 12 18V14H8.00001C7.73479 14 7.48044 13.8946 7.2929 13.7071C7.10536 13.5196 7.00001 13.2652 7.00001 13C7.00001 12.7348 7.10536 12.4804 7.2929 12.2929C7.48044 12.1054 7.73479 12 8.00001 12H12V8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8V12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13Z" fill="#343434"/>
          </svg>
          <svg v-if="showAnswer.includes(25)" class="tw-grow-0 tw-shrink-0" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 13C26 10.4288 25.2376 7.91543 23.8091 5.77759C22.3806 3.63975 20.3503 1.97351 17.9749 0.989572C15.5994 0.00563224 12.9856 -0.251811 10.4638 0.249797C7.94207 0.751404 5.62569 1.98953 3.80761 3.80762C1.98953 5.6257 0.7514 7.94208 0.249792 10.4638C-0.251816 12.9856 0.005626 15.5994 0.989566 17.9749C1.97351 20.3503 3.63975 22.3807 5.77759 23.8091C7.91543 25.2376 10.4288 26 13 26C16.4467 25.9964 19.7512 24.6256 22.1884 22.1884C24.6256 19.7512 25.9964 16.4467 26 13ZM2 13C2 10.8244 2.64514 8.69767 3.85383 6.88873C5.06253 5.07979 6.78049 3.66989 8.79048 2.83733C10.8005 2.00477 13.0122 1.78693 15.146 2.21137C17.2798 2.6358 19.2398 3.68345 20.7782 5.22183C22.3166 6.76021 23.3642 8.72022 23.7886 10.854C24.2131 12.9878 23.9952 15.1995 23.1627 17.2095C22.3301 19.2195 20.9202 20.9375 19.1113 22.1462C17.3023 23.3549 15.1756 24 13 24C10.0836 23.9967 7.28766 22.8367 5.22548 20.7745C3.16329 18.7123 2.00331 15.9164 2 13ZM12 14L8 14C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12L12 12L14 12L18 12C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14L12 14Z" fill="#5723B4"/>
          </svg>
          <h2 class="tw-font-black tw-text-lg tw-leading-[1.4] tw-text-regular-text tw-pr-6" :class="{'tw-text-shyftoff-purple': showAnswer.includes(25)}">
            How is ShyftOff's business model different from traditional employment?
          </h2>
        </div>
        <div class="sm:tw-pl-[50px] tw-grid tw-grid-rows-[0fr] tw-transition-all" :class="{'tw-grid-rows-[1fr]': showAnswer.includes(25)}">
          <div class="tw-overflow-hidden tw-pr-6 tw-border-b-2 tw-border-solid tw-border-[#EBEBEB]" :class="{'tw-pb-11': showAnswer.includes(25)}">
            <p>We are different from traditional employers because we do not hire employees; we employ independent 1099 contractors. 1099 contractors will receive compensation for services rendered but ShyftOff does not withhold income taxes or offer health insurance as a traditional employer would. These will be your responsibility to manage and obtain. It is considered a business opportunity because of this distinction.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<style scoped lang="scss">
  ul {
    @apply tw-pl-4 tw-list-disc tw-my-2;
    li {
      @apply tw-leading-6;
    }
  }
  p {
    @apply tw-my-4;
    &:first-child {
      @apply tw-mt-0;
    }
  }
</style>
