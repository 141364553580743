<script setup lang="ts">
import useAgents from '@/composables/useAgents'
import { type Feedback } from '@/types/feedback'
import { ref, computed, watch, onBeforeMount, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { useFeedbackStore } from '@/stores/feedback'
import { useLayoutStore } from '@/stores/layout'


const { updateFeedback, retrieveAgentFeedback } = useAgents()
const { decreaseUnacknowledged } = useFeedbackStore()
const route = useRoute()
const { setLoadingState } = useLayoutStore()


const props = defineProps(['currentFeedback'])
const feedbackData = ref<Feedback>(props.currentFeedback)
const initialAgentComment = ref(feedbackData.value?.agent_comment ?? '')
const feebackText = computed(() => {
  const txt = feedbackData.value?.text
  return txt?.replace(/(\\r)*\\n/g, '<br>')
})

const loadingAcknowledge = ref(false)
async function acknowledge() {
  loadingAcknowledge.value = true
  const response = await updateFeedback({ id: feedbackData.value.id, is_acknowledged: true, agent_comment: initialAgentComment.value })
  feedbackData.value = response
  if (response) decreaseUnacknowledged()
  loadingAcknowledge.value = false
}

onBeforeMount(async () => {
  const response = await retrieveAgentFeedback(route.params.id as string)
  feedbackData.value = response
  await nextTick()
  setLoadingState(false)

})

watch(() => props.currentFeedback, (newVal) => {
  feedbackData.value = newVal
  initialAgentComment.value = newVal.agent_comment ?? ''
  setLoadingState(false)
})

</script>

<template>
  <div
    class="tw-col-span-12 md:tw-col-span-8 xl:tw-col-span-9 tw-pr-6 tw-py-6 tw-text-regular-text tw-sticky tw-top-0 tw-max-h-[calc(100vh_-_85px)]">
    <div class="tw-flex tw-justify-between tw-items-center">
      <h2 class="tw-text-[22px]">{{ feedbackData?.campaign?.name }}</h2>
      <VBtn :color="feedbackData?.is_acknowledged ? 'primary' : '#F6F2FF'" flat
        :disabled="feedbackData?.is_acknowledged" :loading="loadingAcknowledge" @click="acknowledge" rounded="lg"
        size="large" min-width="0"
        class="tw-pr-7 tw-pl-0 tw-font-semibold tw-text-sm tw-tracking-normal tw-relative tw-flex tw-items-center !tw-text-shyftoff-purple"
        :class="{ 'before:content-[\'\'] before:tw-inline-block before:tw-rounded-full before:tw-w-[18px] before:tw-h-[18px] before:tw-border before:tw-border-solid before:tw-border-[#bea7e9] before:tw-mx-3 before:tw-ml-4': !feedbackData?.is_acknowledged }">
        <i v-if="feedbackData?.is_acknowledged"
          :class="`mdi mdi-check-circle tw-text-xl tw-leading-none tw-text-[#475569] tw-mx-3`"></i>
        <span v-if="feedbackData?.is_acknowledged">ACKNOWLEDGED</span>
        <span v-else>ACKNOWLEDGE</span>
      </VBtn>
    </div>
    <div class="tw-mt-10">
      <h3 class="tw-mb-4">Feedback</h3>
      <p v-html="feebackText"></p>
    </div>
    <div class="tw-mt-10">
      <p class=" tw-font-semibold">
        Questions about your feedback? Please reach out to
        <span class=" tw-text-shyftoff-purple tw-font-semibold">support.</span>
      </p>
    </div>
  </div>
</template>
