import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import * as Sentry from "@sentry/vue"
import { Amplify } from 'aws-amplify'

import App from './App.vue'
import axiosInterceptor from './plugins/axios'
import router from './router'
import mitt from 'mitt'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const emitter = mitt()


// Vuetify

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'

import './assets/sass/main.scss'

const app = createApp(App)
const vuetify = createVuetify({
  defaults: {
    global: {
      style: {
        fontFamily: 'Nunito, sans-serif',
      },
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#5723B4', // #E53935
          secondary: '#FF66C4', // #FFCDD2
        }
      },
    },
  },
})
const head = createHead()

import amplifyconfig from './amplifyconfiguration.json'
Amplify.configure(amplifyconfig)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  beforeSend(event, hint: any) {
    console.log('beforeSend initiated') // temporary left to test sentry
    if (event.level === "error" && (
      hint.originalException?.message === 'Not authenticated' ||
      hint.originalException?.message === 'Agent is not found for the user'
    )) {
      console.log('beforeSend: the issue won\'t be sent to Sentry',  hint.originalException?.message) // temporary left this for test after deployment
      return null
    }

    return event
  },
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://dev-app.shyftoff.com/', 'https://app.shyftoff.com/', 'localhost'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.provide('emitter', emitter)

app.use(pinia)
app.use(axiosInterceptor)
app.use(head)
app.use(router)
app.use(vuetify)

app.mount('#app')
