<script setup lang="ts">
import { useCampaignStore } from '@/stores/campaigns'
import useCampaigns from '@/composables/useCampaigns'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import type { Campaign } from '@/types/campaign'
import { useLayoutStore } from '@/stores/layout'

const { getUserCampaigns, fetchCampaign } = useCampaigns()
const campaignStore = useCampaignStore()
const { setLoadingState } = useLayoutStore()


const route = useRoute()
const router = useRouter()
onMounted(async () => {
  try {
    await getUserCampaigns({ is_active: true })
  } catch {
    setLoadingState(false)
    router.push({path: `/active-campaigns/no-campaigns`})
  }

  if (!campaignStore?.activeCampaigns?.length) {
    router.push({path: `/active-campaigns/no-campaigns`})
  } else if (route.params?.id) {
    const currentCampaign = campaignStore.activeCampaigns.find((campaign: Campaign) => campaign.id === parseInt(route.params.id as string))
    if (!currentCampaign) {
      const additionalCampaign = await fetchCampaign(parseInt(route.params.id as string))
      campaignStore.addActiveCampaign(additionalCampaign)
    }
  } else {
    if (campaignStore.activeCampaigns?.length) {
      router.push({ path: `/active-campaigns/${campaignStore.activeCampaigns[0]?.id || ''}` });
    } else {
      setLoadingState(false)
    }
  }
})

</script>

<template>
  <v-card class="tw-hidden md:tw-block tw-w-[250px] xl:tw-w-[250px] tw-h-screen tw-bg-white !tw-absolute tw-border-r tw-border-t-0 tw-border-b-0 tw-border-l-0 tw-border-gray-200 tw-border-solid tw-left-[70px] xl:tw-left-[250px] tw-top-0 !tw-pt-0 !tw-p-0 !-tw-z-[1] !tw-rounded-l-none !tw-rounded-none tw-shadow-card">
    <v-card-title class="!tw-p-4 tw-border-b tw-border-solid tw-border-b-[#E6E6E6]">
      <div class="tw-flex tw-justify-between tw-items-center">
        <p class="tw-font-extrabold tw-text-base tw-text-regular-text">{{ campaignStore?.activeCampaigns?.length ? 'My Campaigns' : 'No active campaigns' }}</p>
      </div>
    </v-card-title>
    <v-card-text class="!tw-p-0 !tw-text-base tw-h-[calc(100%_-_63px)] tw-overflow-y-auto tw-pr-4 -tw-mr-4 tw-w-full">
      <div>
        <v-expansion-panels v-if="campaignStore.activeCampaigns?.length" class="tw-max-w-[250px] xl:tw-max-w-[250px]">
          <v-expansion-panel
            class="tw-relative"
            v-for="campaign in campaignStore.activeCampaigns"
            :key="campaign.id">
            <v-expansion-panel-title class="!tw-py-0 tw-opacity-70 hover:tw-opacity-100" :class="{'!tw-opacity-100': parseInt($route.params.id as string) == campaign?.id}">
              <template #default>
                <a :href="`/active-campaigns/${campaign.id}`" @click.stop class="tw-flex tw-items-center tw-grow tw-gap-3 tw-relative tw-z-30 tw-h-full">
                  <!-- <div class="tw-hidden tw-w-[60px] tw-h-[60px] xl:tw-inline-block tw-grow-0 tw-ml-3">
                    <img v-if="campaign.logo_url" :src="campaign.logo_url" class="tw-object-contain tw-h-full tw-mix-blend-multiply"  />
                    <img v-else src="@/assets/images/image-placeholder.svg" class="tw-object-contain tw-h-full tw-mix-blend-multiply" alt="">
                  </div> -->
                  <span class="tw-inline-flex tw-h-full tw-items-center tw-px-2 tw-align-middle tw-grow tw-leading-5 tw-text-gray-400 tw-font-semibold" :class="{'!tw-opacity-100 !tw-text-regular-text tw-bg-[#f6f0ff]': parseInt($route.params.id as string) == campaign?.id}">
                    {{ campaign?.name }}
                  </span>
                </a>
              </template>
              <template #actions>
                <div>
                  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.04401 6L0.809756 -9.85977e-07L11.2783 -7.07899e-08L6.04401 6Z" fill="#5723B4"/>
                  </svg>
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="tw-space-y-4">
                <div class="tw-flex tw-flex-col tw-items-start tw-gap-y-4">
                  <div class="tw-flex tw-flex-col tw-items-baseline tw-justify-between tw-w-full">
                    <small class="tw-text-gray-500 tw-uppercase tw-text-xs tw-font-bold tw-inline-block tw-mb-1">
                      Status
                    </small>
                    <span class="tw-text-sm tw-capitalize tw-text-left">
                      {{ campaign.agent_status.name }}
                    </span>
                  </div>
                  <div class="tw-flex tw-flex-col tw-items-baseline tw-justify-between tw-w-full">
                    <small class="tw-text-gray-500 tw-uppercase tw-text-xs tw-font-bold tw-inline-block tw-mb-1">
                      Base agent pay
                    </small>
                    <span class="tw-text-sm tw-capitalize tw-text-left" v-if="campaign.min_hourly_pay && campaign.max_hourly_pay">
                        ${{ campaign.min_hourly_pay }} - ${{ campaign.max_hourly_pay }}
                    </span>
                    <p class="tw-text-sm tw-capitalize tw-text-left" v-else>
                        -
                    </p>
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="empty-state" v-else>
          <div
            v-for="(_, index) in 3"
            :key="index"
            class="tw-border-b tw-border-solid tw-border-b-[#E6E6E6] tw-ml-5 tw-pt-4 tw-pb-4"
          >
            <img
              class="tw-w-full tw-max-w-[200px]"
              src="@/assets/images/empty-state--my-campaigns.svg"
              alt="">
          </div>
        </div>
      </div>

      <!-- <VBtn v-if="campaignStore.getActiveCount >= 5 && campaignStore.getActiveCampaigns.length < campaignStore.getActiveCount" @click="loadMoreCampaigns" :loading="loadingCampaigns" color="primary" flat variant="outlined" rounded="0" width="100%" height="64px" class="tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-t !tw-border-b-[#EAEAEA] !tw-border-t-[#EAEAEA] tw-border-solid tw-text-gray-500 tw-text-base tw-w-full tw-h-10 tw-font-semibold hover:tw-text-regular-text [&>span]:tw-text-gray-500 [&>span]:hover:tw-text-shyftoff-purple [&>span]:tw-normal-case [&>span]:tw-tracking-normal hover:!tw-bg-transparent [&>span]:hover:!tw-bg-transparent tw-max-w-[335px]">
        Load more
      </VBtn> -->
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
  .v-btn {
    @apply tw-grid-cols-[auto_1fr_auto] #{!important};
    ::v-deep(.v-btn__content) {
      @apply tw-w-full;
      svg {
        @apply tw-fill-[#AEAEAE];
      }
    }
    &:hover {
      @apply tw-border-shyftoff-purple/20 tw-bg-[#f3f2fb]/50;
      ::v-deep(.v-btn__content) {
        span {
          @apply tw-text-shyftoff-purple;
        }
        svg {
          @apply tw-fill-shyftoff-purple/70;
        }
      }
    }
  }
  :deep(.v-expansion-panel) {
    background: none;
    &:hover {
        &:after {
            // @apply tw-border-none;
        }
        + div {
            &:after {
                // @apply tw-border-none;
            }
        }
    }
  }
  :deep(.v-expansion-panel-title) {
    @apply tw-justify-around tw-gap-0 tw-pl-0 tw-pr-0 tw-bg-none tw-h-[64px] tw-items-start  #{!important};
    &:hover {
        span {
            @apply tw-text-shyftoff-purple;
        }
    }
    &.v-expansion-panel-title--active {
      .v-expansion-panel-title__icon {
        div {
          @apply tw-rotate-180;
        }
      }
    }
    a {
      @apply tw-p-2;
      span {
        @apply tw-rounded-lg tw-px-4;
      }
    }

    .v-expansion-panel-title__overlay {
        @apply tw-hidden;
      }
    .v-expansion-panel-title__icon {
      @apply tw-grow-0 tw-shrink-0 tw-m-0 tw-w-[40px] tw-basis-[40px] tw-text-center tw-justify-center tw-h-[calc(100%_-_16px)] tw-items-center hover:tw-bg-slate-100 tw-rounded-[8px_0_0_8px] tw-my-2;
      div {
        @apply tw-transition-all;
      }
    }
  }
  :deep(.v-expansion-panel-text) {
    .v-expansion-panel-text__wrapper {
      @apply tw-px-4 tw-pb-4 tw-pl-[1.75rem];
    }
  }
  :deep(.v-expansion-panel__shadow) {
    @apply tw-hidden;
  }
  :deep(.v-expansion-panel:not(:first-child):after) {
    @apply tw-border-[#E6E6E6] tw-w-[90%] tw-left-auto tw-z-30;
  }
  :deep(.v-expansion-panel--active:not(:first-child)),
  :deep(.v-expansion-panel--active + .v-expansion-panel) {
    @apply tw-mt-0;
  }
  :deep(.v-expansion-panel--active:not(:first-child)::after),
  :deep(.v-expansion-panel--active + .v-expansion-panel::after) {
    @apply tw-opacity-100;
  }
</style>
