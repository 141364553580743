<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import { useLayoutStore } from '@/stores/layout'

const { params }: any = useRoute()
const { setLoadingState } = useLayoutStore()

const router = useRouter()
if (!['general', 'services'].includes(params.chapter)) {
  router.push({path: '/faq/section1'})
}
const searchText = ref()
function searchFaq() {

}
onMounted(async () => {
  console.log('object');
  setTimeout(() => {
    setLoadingState(false)

  }, 300)
})
</script>
<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4">
    <header class="tw-flex tw-items-center tw-pb-6 xl:tw-pb-7">
      <h1 class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-extrabold tw-w-full tw-text-center md:tw-text-left">
        FAQ
      </h1>
    </header>
    <div class="tw-space-y-6" >
      <v-card border="#E8E8EA" class="!tw-p-0 tw-overflow-visible">
        <v-card-text class="tw-p-6 tw-py-0 tw-pr-0 tw-text-base">
          <div class="tw-grid tw-grid-cols-12 tw-reverse">
            <RouterView v-slot="{ Component }">
              <component
                :is="Component"
                :searchText="searchText"
               />
            </RouterView>
            <div class="tw-col-span-12 -tw-order-1 md:tw-order-2 md:tw-col-span-4 xl:tw-col-span-3 tw-border-l-2 tw-border-solid tw-border-[#EBEBEB] tw-mt-6 md:tw-mt-0">
              <div class="tw-p-6 tw-sticky tw-top-0">
                <div>
                  <h3 class="tw-font-extrabold tw-text-lg tw-mb-8 tw-text-regular-text">Table of contents</h3>
                  <VTextField
                    v-model="searchText"
                    @update:modelValue="searchFaq"
                    rounded="lg"
                    class="border-radius-override tw-mb-2"
                    label="Search"
                    variant="outlined"
                    density="comfortable"
                  />
                  <ul>
                    <li>
                      <a href="/faq/section1" class="tw-block xl:tw-text-lg tw-font-semibold tw-text-shyftoff-pink tw-py-3 tw-relative before:tw-content-[''] before:tw-absolute before:tw-h-full before:tw-top-0 before:-tw-left-[26px] before:tw-b-l before:tw-b-solid before:tw-w-[2px] before:tw-bg-shyftoff-pink">
                        Section 1
                      </a>
                    </li>
                    <li>
                      <a href="/faq/section2" class="tw-block xl:tw-text-lg tw-font-semibold tw-text-regular-text tw-py-3">
                        Section 2
                      </a>
                    </li>
                    <li>
                      <a href="/faq/section3" class="tw-block xl:tw-text-lg tw-font-semibold tw-text-regular-text tw-py-3">
                        Section 3
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>


        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
