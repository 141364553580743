<script setup lang="ts">
import useAgents from '@/composables/useAgents'
import { usePaymentsStore } from '@/stores/payments'
import { onBeforeMount, ref } from 'vue'
import HtmlBlock from '@/components/shared/HtmlBlock.vue'
import useHelpers from '@/composables/useHelpers'
import { useLayoutStore } from '@/stores/layout'

const { fetchAgentPayroll } = useAgents()
const paymentsStore = usePaymentsStore()
const { dateWithoutTimezone } = useHelpers()
const { setLoadingState } = useLayoutStore()

const loading = ref(true)

onBeforeMount(async () => {
  if (!paymentsStore.getPayments?.length) {
    await fetchAgentPayroll()
    loading.value = false
  } else {
    loading.value = false
  }
  setLoadingState(false)
})

function subtractDays(dateString: string | number | Date, days: number) {
  const noTimezoneDate = dateWithoutTimezone(dateString)
  noTimezoneDate.setDate(noTimezoneDate.getDate() - days)
  return noTimezoneDate
}
function endDate(dateString: string | number | Date) {
  return dateWithoutTimezone(dateString)
}

</script>
<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-mx-auto tw-max-w-[1536px] tw-flex-grow">
    <header class="tw-flex tw-items-center !tw-pb-0 xl:tw-pb-7">
      <h1
        class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-black tw-w-full tw-text-center md:tw-text-left">
        My Pay
      </h1>
    </header>
    <div>
      <HtmlBlock title="Important Payroll Information" link="https://app.gusto.com/login" linkText="Gusto Login">
        Payroll information displayed below is based on productive hour calculation. For a full view of your payroll,
        please check Gusto.
      </HtmlBlock>
      <v-card class="tw-bg-white tw-text-regular-text !tw-pb-1 tw-pt-6">
        <div v-if="!loading">
          <v-table density="comfortable" class="tw-bg-transparent">
            <thead>
              <tr>
                <th class="tw-uppercase !tw-font-bold tw-w-1/4 tw-text-light-text">
                  Start Date
                </th>
                <th class="tw-uppercase !tw-font-bold tw-w-1/4 tw-text-light-text">
                  End Date
                </th>
                <th class="tw-uppercase !tw-font-bold tw-w-1/4 tw-text-light-text">
                  Hours
                </th>
                <th class="tw-uppercase !tw-font-bold tw-w-1/4 tw-text-light-text">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(payment, index) in paymentsStore.getPayments" :key="index" class="tw-text-base">
                <td class="tw-text-left"> {{ subtractDays(payment.pay_date, 6).toLocaleDateString('en-US') }} </td>
                <td class="tw-text-left"> {{ endDate(payment.pay_date).toLocaleDateString('en-US') }} </td>
                <td class="tw-text-left"> {{ payment.hours?.toFixed(2) }} </td>
                <td class="tw-text-left"> ${{ payment.amount }} </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <div v-else class="tw-mb-6">
          <v-skeleton-loader type="table-thead">
          </v-skeleton-loader>
          <v-skeleton-loader type="table-tbody">
          </v-skeleton-loader>
        </div>
      </v-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.v-skeleton-loader__table-thead) {
  @apply tw-flex-nowrap;
  > div {
    @apply tw-max-w-[120px] tw-mx-auto tw-flex-shrink;
  }
}
:deep(.v-skeleton-loader__table-row-divider) {
  &:last-child {
    > div:last-child {
      @apply tw-hidden;
    }
  }
}
</style>
