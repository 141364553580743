<template>
  <VueApexCharts type="bar" height="100%" :options="chartOptions" :series="props.chartSeries" class="tw-max-w-[calc(100%_-_1px)]" />
</template>

<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts'
import { computed, onMounted } from 'vue'


const props = defineProps(['chartSeries', 'chartColor'])


const chartOptions = computed(() => ({
  chart: {
    type: 'bar',
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    },
    animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 400,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    }
  },
  responsive: [
    {
      breakpoint: 1999,
      options: {
        plotOptions: {
          bar: {
            columnWidth: 22
          }
        }
      }
    },
    {
      breakpoint: 1599,
      options: {
        plotOptions: {
          bar: {
            columnWidth: 30
          }
        }
      }
    },
    {
      breakpoint: 999,
      options: {
        height: 200,
        plotOptions: {
          bar: {
            columnWidth: 25
          }
        }
      }
    },
    {
      breakpoint: 720,
      options: {
        height: 200,
        plotOptions: {
          bar: {
            columnWidth: 20
          }
        }
      }
    },
  ],
  plotOptions: {
    bar: {
      columnWidth: 30,
      borderRadius: 4,
      borderRadiusApplication: 'end',
      colors: {
        backgroundBarColors: [
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3",
          "#F1F0F3"
        ],
      }
    }
  },
  grid: {
    borderColor: '#EDEDED'
  },
  colors: [props.chartColor],
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false,
  },
  states: {
    hover: {
      filter: {
        type: 'darken',
        value: 0.85
      }
    }
  },
  tooltip: {
    followCursor: true,
    style: {
      fontSize: '12px',
      fontFamily: 'Nunito',
    },
    custom: ({ series, seriesIndex, dataPointIndex }: any) => {
      let expectedValue: number|null = null
      if ('goals' in props.chartSeries[0].data[dataPointIndex]) {
        expectedValue = props.chartSeries[0].data[dataPointIndex].goals[0].value
      }
      if (expectedValue) {
        return '<div class="tw-border-none tw-text-regular-text">' +
        '<div class="tw-bg-[#F3F3F3] tw-p-2">' +
        '<h3>' + props.chartSeries[0].data[dataPointIndex].x + '</h3>' +
        '</div>' +
        '<div class="tw-p-2 tw-space-y-2">' +
        '<div>Actual:<span class="tw-font-extrabold tw-ml-1">' + series[seriesIndex][dataPointIndex] + '%</span></div>' +
        '<div class="tw-flex tw-items-center"><span class="tw-block tw-bg-[#EA87CA] tw-h-[4px] tw-w-[7px] tw-mr-2"></span>Expected: <span class="tw-font-extrabold tw-ml-1">' + expectedValue + '%</span></div>' +
        '</div>' +
        '</div>'
      } else {
        return '<div class="tw-border-none tw-text-regular-text">' +
        '<div class="tw-bg-[#F3F3F3] tw-p-2">' +
        '<h3>' + props.chartSeries[0].data[dataPointIndex].x + '</h3>' +
        '</div>' +
        '<div class="tw-p-2 tw-space-y-2">' +
        '<div>Actual:<span class="tw-font-extrabold tw-ml-1">' + series[seriesIndex][dataPointIndex].toFixed(2) + ' hours</span></div>' +
        '</div>' +
        '</div>'
      }

    }
  },
  xaxis: {
    labels: {
      rotate: 0,
      style: {
        fontFamily: 'Nunito',
        fontSize: '13px',
        colors: '#6b7280'
      }
    },
    tickAmount: 4,
    axisTicks: {
      color: '#EDEDED',
    },
    axisBorder: {
      height: 0
    },
    crosshairs: {
      opacity: 1,
      fill: {
        gradient: {
            colorFrom: '#EFEBF5',
            colorTo: '#EFEBF5',
            stops: [0, 100],
            opacityFrom: 0.8,
            opacityTo: 0.8,
        }
      }
    }
  },
  yaxis: {
    labels: {
      style: {
        fontFamily: 'Nunito',
        fontSize: '13px',
        colors: '#6b7280'
      }
    }
  },
}))


onMounted(() => {
})


</script>
