<script setup lang="ts">
import ReviewInteractionForm from '@/components/reviews/ReviewInteractionForm.vue';
import useReviewInteraction from '@/composables/useReviewInteraction';
import { useLayoutStore } from '@/stores/layout';
import { onBeforeMount } from 'vue';

const { setLoadingState } = useLayoutStore()
const { currentInteraction, interactionSubmitted, loadingInteraction, noMoreInteractions, getInteraction } = useReviewInteraction()

onBeforeMount(() => {
    setLoadingState(false)
})

</script>

<template>
    <div class="tw-w-full tw-min-h-screen tw-flex tw-flex-col tw-px-6 tw-pt-6 tw-pb-4 tw-gap-y-4 md:tw-gap-y-8">
        <header>
            <h1
                class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-black tw-w-full tw-text-center md:tw-text-left">
                Peer to Peer QA
            </h1>

            <p class=" tw-text-light-text tw-italic tw-mt-3 tw-text-center md:tw-text-left">
                Interactions are presented randomly to ensure an impartial evaluation process. Please conduct your
                review objectively. Compensation is provided for each review, with increased rewards for more complex
                interactions. Thank you for your diligence.
            </p>
        </header>

        <!-- Ready to Review -->
        <div v-if="!currentInteraction && !noMoreInteractions && !interactionSubmitted"
            class=" tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-y-4 tw-py-10 tw-mt-10">
            <p class=" tw-text-lg tw-font-semibold tw-text-center">Click on the on the button to start reviewing
                interactions</p>
            <v-btn color="primary" class=" tw-capitalize tw-font-semibold tw-rounded-lg" :loading="loadingInteraction"
                :disabled="loadingInteraction" @click="getInteraction">Start reviewing</v-btn>
            <img src="@/assets/images/empty-state--reviews.svg"
                class="tw-block !tw-min-h-[160px] sm:!tw-min-h-[270px] tw-max-w-full tw-relative tw-left-1/2 -tw-translate-x-1/2 tw-object-cover tw-scale-90" />
        </div>

        <!-- Reviewing Form -->
        <ReviewInteractionForm v-if="currentInteraction && !interactionSubmitted"
            :currentInteraction="currentInteraction" />

        <!-- No More Interactions -->
        <div v-if="noMoreInteractions"
            class=" tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-y-4 tw-py-10">
            <p class=" tw-text-lg tw-font-semibold tw-text-center tw-max-w-[430px]">There are currently no more
                interactions to review. Thank you for your contributions! Please check back later for new interactions.
            </p>
            <img src="@/assets/images/empty-state--reviews.svg"
                class="tw-block !tw-min-h-[160px] sm:!tw-min-h-[270px] tw-max-w-full tw-relative tw-left-1/2 -tw-translate-x-1/2 tw-object-cover tw-scale-90" />
        </div>

        <!-- Interaction Submitted -->
        <div v-if="interactionSubmitted"
            class=" tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-y-4 tw-py-10">
            <i class="mdi mdi-check-circle tw-text-shyftoff-purple tw-text-7xl" />
            <p class=" tw-text-lg tw-font-semibold tw-text-center tw-max-w-[430px]">Thank you for your feedback! If
                you would like to
                continue reviewing other interactions, please click the button below.</p>
            <v-btn color="primary" class=" tw-capitalize tw-font-semibold tw-rounded-lg" :loading="loadingInteraction"
                :disabled="loadingInteraction" @click="getInteraction">Get a new interaction</v-btn>
            <img src="@/assets/images/empty-state--reviews.svg"
                class="tw-block !tw-min-h-[160px] sm:!tw-min-h-[270px] tw-max-w-full tw-relative tw-left-1/2 -tw-translate-x-1/2 tw-object-cover tw-scale-90" />
        </div>
    </div>
</template>