<script setup lang="ts">
import { onMounted, ref, inject } from 'vue'
import { RouterView } from 'vue-router'

const showPasswordChangedNotification = ref(false)
const emitter: any = inject('emitter')

onMounted(() => {
  emitter.on('confirmPassword', () => {
    showPasswordChangedNotification.value = true
  })
})

</script>
<template>
  <main class="!tw-grid tw-grid-cols-12 tw-items-center tw-grid-flow-row tw-min-h-screen tw-w-full tw-bg-white">
    <div class="tw-rounded-xl tw-col-span-7 xl:tw-col-span-6 tw-pr-0 tw-hidden lg:tw-block">
      <div class="tw-h-full tw-rounded-xl tw-flex tw-items-center tw-justify-end">
        <img class="tw-w-full !tw-inline-block tw-h-screen tw-max-h-[869px] tw-max-w-[1000px] tw-rounded-xl tw-object-cover tw-p-4 tw-rounded-tl-[180px] tw-rounded-br-[180px]" src="@/assets/images/shyftoff-login-image.jpeg" alt="ShyftOff" />
      </div>
    </div>
    <div class="tw-col-span-12 tw-p-6 lg:tw-col-span-5 xl:tw-col-span-6 tw-max-h-[869px] tw-h-full lg:tw-p-6">
      <div class="tw-flex tw-justify-between tw-items-center">
        <a href="https://www.shyftoff.com">
          <img src="@/assets/images/logo-shyftoff--purple.svg" width="136" alt="">
        </a>
        <a href="https://www.shyftoff.com/agents-overview" class="tw-inline-flex tw-justify-center tw-items-center tw-rounded-lg tw-px-7 tw-font-semibold tw-text-sm !tw-text-shyftoff-purple tw-uppercase tw-h-11 tw-min-w-[78px] tw-bg-[#F6F2FF] hover:tw-bg-[#f0ebfd]">
          Sign up
        </a>
      </div>
      <RouterView />
    </div>
    <VSnackbar v-model="showPasswordChangedNotification" :timeout="3000" :color="'green-darken-2'">
      Your password has been changed.
    </VSnackbar>
  </main>
</template>
<style scoped lang="scss">
::v-deep(.v-input) {
  @apply tw-bg-transparent tw-relative;
  &:hover {
    .v-field__outline {
      > div,
      .v-field__outline__notch:before,
      .v-field__outline__notch:after {
        @apply tw-border-grey-border #{!important};
      }
    }
  }
  .v-field--focused {
    .v-field__outline {
      .v-field__outline__notch {
        @apply tw-border-t-2 tw-border-b-2;
      }
    }
  }
  .v-field__outline {
    > div,
    .v-field__outline__notch:before,
    .v-field__outline__notch:after {
      @apply tw-border-grey-border/50 #{!important};
    }
    .v-field__outline__notch:after,
    .v-field__outline__start,
    .v-field__outline__end {
      @apply tw-opacity-100 tw-bg-transparent;
    }
    .v-field-label {
      @apply tw-bg-white;
    }
  }
  .v-input__control {
    .v-field {
      &:not(.v-field--active) {
        .v-field__outline {
          .v-field__outline__notch:before {
            @apply tw-opacity-100;
          }

        }
        .v-field-label {
          @apply tw-text-gray-500 tw-opacity-100;
        }
      }
      &.v-field--disabled {
        @apply tw-opacity-90 tw-text-gray-500;
      }
    }
  }
  .v-field-label {
    @apply tw-text-shyftoff-purple tw-opacity-100;
  }
  .v-input__details {
    @apply tw-hidden;
  }
}

::v-deep(.v-input--error) {
    &:hover {
      .v-field__outline {
        > div,
        .v-field__outline__notch:before,
        .v-field__outline__notch:after {
          @apply tw-border-red-300 #{!important};
        }
      }
    }
    .v-input__details {
      @apply tw-flex;
      .v-messages {
        .v-messages__message {
          @apply tw-leading-4 #{!important};
        }
      }
    }
    .v-field-label {
      @apply tw-text-red-700 #{!important};
    }
    .v-field__outline {
      > div,
      .v-field__outline__notch:before,
      .v-field__outline__notch:after {
        @apply tw-border-red-500 #{!important};
      }
    }
  }

::v-deep(.v-btn) {
  @apply tw-text-regular-text;
  &:disabled {
    @apply tw-bg-slate-300 #{!important};
    .v-btn__content {
      @apply tw-text-slate-900;
    }
  }
}

::v-deep(.v-select) {
  @apply tw-text-regular-text tw-min-w-[250px];
  .v-icon {
    @apply tw-text-shyftoff-purple/80 tw-opacity-100;
  }
}

::v-deep(.v-checkbox) {
  .v-label {
    @apply tw-opacity-100;
  }
}

</style>

