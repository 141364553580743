<script setup lang="ts">
import { onMounted } from 'vue';
import { useLayoutStore } from '@/stores/layout';
import HtmlBlock from '@/components/shared/HtmlBlock.vue'

const { setLoadingState } = useLayoutStore()

onMounted(() => {
  setLoadingState(false)
})

</script>
<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-mx-auto tw-max-w-[1536px] tw-flex-grow">
    <header class="tw-flex tw-items-center">
      <h1 class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-black tw-w-full tw-text-center md:tw-text-left">
        Campaign Policies
      </h1>
    </header>
    <HtmlBlock>
      <ol class="tw-pl-4 tw-list-decimal tw-text-sm tw-leading-6 tw-text-regular-text">
        <li><b class="tw-font-bold">Schedule Reliability:</b> Schedule Reliability is a calculation of your productive time worked on a campaign divided by the number of hours you scheduled yourself on that same campaign.  By picking a schedule you are highly confident you can work, and then working that entire schedule, you will keep your Schedule Reliability score high.  The goal is to maintain a 95% or higher Schedule Reliability score. Let's say you scheduled 20 hours and were active in production for 19 hours, that would be a 95% Schedule Reliability.</li><br>
        <li><b class="tw-font-bold">No Call Avoidance:</b> When in a productive status, you are expected to be ready and accepting of handling contacts.  Examples of call avoidance are not accepting a call when it is offered to you, not responding when a call is connected to you, using hold times in excess, extremely low AHT with high contact counts, and staying in an after call status longer than required for the specific campaign.  Call avoidance behaviors can result in being removed from the campaign and ShyftOff.</li><br>
        <li><b class="tw-font-bold">Quiet Workspace:</b> When working on a campaign with ShyftOff, it is expected that you are able to provide a quiet, distraction free work environment.  This will allow you to maintain focus and minimize background noise.  Examples of impermissible background noise are televisions, music, background conversations, children, pets, and construction.  If you are unable to provide a quiet workspace during  a shift, you should drop that shift so someone else can work it.</li><br>
        <li><b class="tw-font-bold">Bring Your Own Device:</b> You are expected to supply your own computer, internet and headset that meets the requirements specified on a campaign.  Any issues related to your computer, internet and/or headset must be fixed by you.  Time in an unproductive status related to these issues is not payable.  If you are experiencing a technical issue, you must notify ShyftOff Leadership right away so they are aware, and can help diagnose or troubleshoot when applicable.</li><br>
        <li><b class="tw-font-bold">Campaign Participation:</b> Once in production on a campaign, if you do not complete productive time within a 21 day period you will be moved to an inactive campaign status.  Should you wish to resume the campaign, you will be given priority access when a new spot opens.  Rejoining the campaign will require a campaign exam to ensure you meet certification requirements.</li><br>
        <li><b class="tw-font-bold">Location:</b> ShyftOff only contracts with US based and eligible to work contractors.  You are not able to access certification material, or go into production outside of the US.  Compliance is of the utmost importance, and operating within the US is a requirement from both ShyftOff and our clients.  If you are flagged for working outside the US, that is grounds for removal from the campaign and ShyftOff.  Similarly, using a VPN to conceal your location will have the same consequences.</li>
      </ol>
    </HtmlBlock>
  </div>
</template>
