import axios, { isAxiosError } from 'axios'
import { useProfileStore } from '@/stores/profile'
import useHelpers from './useHelpers'

export default function useZendesk() {
  const { errorHelper } = useHelpers()
  async function sendTicket(payload: { message: string; email: string; subject: string, attachments?: any, uploads?: string[] }) {
    try {
      const apiBaseUrl = import.meta.env.VITE_API_URL
      const profileStore = useProfileStore()
      // const token = `Basic ${btoa(zendeskUsername + '/token:' + zendeskApiToken)}`
      const response = await axios.post(`${apiBaseUrl}/zendesk/api/v2/requests`, {
        request: {
          comment: {
            body: payload.message,
            uploads: payload.uploads ?? [],
            attachments: payload.attachments ?? []
          },
          priority: 'urgent',
          subject: payload.subject,
          requester: { name: profileStore.profile?.agent?.name, email: payload.email },
        }
      })
      return response
    } catch (error) {
      console.error(error)
      if (isAxiosError(error)) {
        errorHelper(error)
      }
    }
  }

  async function uploadAttachment(attachment: { data: any; name: string; type: string; }) {
    try {
      const apiBaseUrl = import.meta.env.VITE_API_URL
      const response = await axios.post(`${apiBaseUrl}/zendesk/api/v2/uploads.json?filename=${attachment.name}`, attachment.data, {
        headers: {
          'Content-Type': 'application/binary',
        }
      })
      return response.data?.upload
    } catch (error) {
      console.error(error)
      if (isAxiosError(error)) {
        errorHelper(error)
      }
    }
  }

  return {
    sendTicket,
    uploadAttachment
  }
}
