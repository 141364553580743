import { ref } from 'vue'
import axios, { isAxiosError } from 'axios'
import useHelpers from './useHelpers'
import type { Interaction, InteractionReview } from '@/types/feedback'

const currentInteraction = ref<Interaction>()
const loadingInteraction = ref(false)
const interactionSubmitted = ref(false)
const noMoreInteractions = ref(false)

export default function useReviewInteraction() {
  const apiBaseUrl = import.meta.env.VITE_API_URL
  const { errorHelper } = useHelpers()

  const getInteraction = async () => {
    currentInteraction.value = undefined
    loadingInteraction.value = true

    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/interaction_reviews/interaction`)
      if (data) {
        currentInteraction.value = data
      } else {
        noMoreInteractions.value = true
      }
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    } finally {
      interactionSubmitted.value = false
      loadingInteraction.value = false
    }
  }

  const createInteractionReview = async (data: InteractionReview) => {
    try {
      await axios.post(`${apiBaseUrl}/api/interaction_reviews`, data)
      interactionSubmitted.value = true
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  return {
    getInteraction,
    createInteractionReview,
    currentInteraction,
    interactionSubmitted,
    loadingInteraction,
    noMoreInteractions
  }
}
