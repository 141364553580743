<script setup lang="ts">
import { onMounted } from 'vue';
import { useLayoutStore } from '@/stores/layout'

const { setLoadingState } = useLayoutStore()

onMounted(() => {
  setLoadingState(false)
})

</script>
<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-10 tw-pb-4 tw-mx-auto tw-max-w-[1536px] md:!tw-pl-[359px] tw-flex-grow">
    <div class="tw-text-center tw-pt-10 tw-mb-8">
      <h5 class="tw-font-extrabold tw-text-lg tw-mb-1 ">
        No active on any campaigns.
      </h5>
      <p class="tw-text-darker-light-text">Go to
        <router-link :to="'/campaigns/'">
          <span class="tw-text-[17px] tw-text-shyftoff-purple ">Open Campaigns</span>
        </router-link>
        to Apply</p>
    </div>
  </div>
</template>
