import axios, { AxiosError } from 'axios'
import router from '@/router'
import route from '@/router'
import { useProfileStore } from '@/stores/profile'
import type { ShyftError } from '@/types/error'



function logOut() {
  localStorage.clear()
  router.go(0) // refresh the page to clear vuex store (maybe to find better solution)
  router.push('/login')
}

function handleError(error: AxiosError<ShyftError>) {
  const profileStore = useProfileStore()
  profileStore.setError(null)
  if (error instanceof AxiosError && error.response) {
    // Check for specific status codes and show toast messages accordingly
    const errorData = error.response.data
    let errorDetail = errorData?.detail ?? null
    let errorMessage: string = ''

    if (error.response.status === 429) {
      errorDetail = 'Please try again later'
    }

    if (typeof errorDetail === 'string') {
      errorMessage = errorDetail
    } else if (Array.isArray(errorDetail)) {
      if (errorDetail.length) {
        errorMessage = errorDetail[0].msg
      }
    } else if (errorDetail == null) {
      errorMessage = error.message
    }
    if (errorMessage) {
      setTimeout(() => {
        profileStore.setError(errorMessage as string)
      }, 200)
    }
  } else if (error.request) {
  // The request was made, but no response was received
    profileStore.setError('No response received from the server.')
  } else {
  // A different error occurred here
    profileStore.setError('An unexpected error occurred.222')
  }
}

export default function () {
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 401 && route.currentRoute.value.path !== '/login') {
        // unauthenticated
        logOut()
      }
      handleError(error)
      return Promise.reject(error)
    }
  )
}
