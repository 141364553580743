import { ref } from 'vue'
import axios, { isAxiosError } from 'axios'
import type { User, UserStats, UserDailyStats } from '@/types/user'
import type { Notification } from '@/types/notification'
import { useNotificationStore } from '@/stores/notifications'
import { useProfileStore } from '@/stores/profile'
import useHelpers from './useHelpers'


export default function useUser() {
  const apiBaseUrl = import.meta.env.VITE_API_URL
  const userDetails = ref({} as User)
  const userStats = ref([] as UserStats[])
  const userNotifications = ref()
  const userDailyStats = ref([] as UserDailyStats[])
  const userDailyByCampaignStats = ref([] as UserDailyStats[])
  const userStatsByCampaign = ref([{ campaign: { name: 'All campaigns' } }])
  const timezoneList = ref([] as string[])
  const notificationStore = useNotificationStore()
  const profileStore = useProfileStore()
  const { errorHelper } = useHelpers()


  async function getUserDetails() {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me`)
      userDetails.value = data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getUserStats() {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/users/me/stats`)
      userStats.value = response.data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getUserDailyByCampaignStats() {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/users/me/stats/daily-by-campaign`)
      userDailyByCampaignStats.value = response.data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getUserDailyStats(id: number|undefined) {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/agents/${id}/stats/daily`)
      userDailyStats.value = response?.data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getUserCampaignStats() {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/users/me/stats/by-campaign`)
      userStatsByCampaign.value.push(...response.data)
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getUserNotifications(payload: {
    type: 'all' | 'unread';
    options?: {
      is_sent?: boolean;
      is_received?: boolean;
      is_read?: boolean;
      media?: number;
      offset?: number;
      limit?: number;
      with_count?: number;
    }
  }) {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/users/me/notifications`, {
        params: {
          is_received: payload?.options?.is_received ?? null,
          is_read: payload?.options?.is_read ?? null,
          offset: payload?.options?.offset ?? 0,
          limit: payload?.options?.limit ?? null,
          with_count: payload?.options?.with_count ?? false
        }
      })
      const webNotifications = response.data.filter((notification: Notification) => notification.media === 1)
      switch (payload.type) {
        case 'all': {
            notificationStore.setNotifications(JSON.parse(JSON.stringify(webNotifications)))
          }
          break
        case 'unread': {
            notificationStore.setUnreadNotifications(JSON.parse(JSON.stringify(webNotifications)))
          }
          break
      }
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getTimezones() {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/timezones`, {
        params: {
          is_active: true
        }
      })
      timezoneList.value = data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function updateSettings(payload: { agent: { email?: string; phone?: string; notify_by_email?: boolean; notify_by_sms?: boolean; timezone?: string } }) {
    try {
      const { data } = await axios.patch(`${apiBaseUrl}/api/users/me`, payload)
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function acquireGeolocationQualification() {
    try {
      const { data } = await axios.put(`${apiBaseUrl}/api/users/me/geolocation-qualifications`)
      console.log("🚀 ~ acquireGeolocationQualification ~ data:", data)

      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  return {
    userDetails,
    userStats,
    userDailyStats,
    userStatsByCampaign,
    userNotifications,
    timezoneList,
    userDailyByCampaignStats,
    getUserDetails,
    getUserStats,
    getUserDailyStats,
    getUserCampaignStats,
    getUserNotifications,
    getTimezones,
    updateSettings,
    getUserDailyByCampaignStats,
    acquireGeolocationQualification
  }
}
