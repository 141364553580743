<script setup lang="ts">

</script>
<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-mx-auto tw-max-w-[1536px]">
    <header class="tw-flex tw-items-center tw-pb-6 xl:tw-pb-7">
      <h1 class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-extrabold tw-w-full tw-text-center md:tw-text-left">
        Support
      </h1>
    </header>
    <div>
       <iframe id="JotFormIFrame-233316477056357" title="New Customer Registration Form" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://form.jotform.com/233203903091042" frameborder="0" style="min-width:100%;max-width:100%;height:calc(100vh - 100px);border:none;" scrolling="no" > </iframe>
    </div>
  </div>
</template>
