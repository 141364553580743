<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useLayoutStore } from '@/stores/layout'
import { onMounted } from 'vue'

const route = useRoute()
const router = useRouter()
const { setLoadingState } = useLayoutStore()
if (route.path === '/settings') router.replace({ path: '/settings/profile' })

onMounted(() => {
  setTimeout(() => {
    setLoadingState(false)
  }, 300)
})

</script>

<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-flex-grow">
    <header class="tw-flex tw-items-center tw-pb-6 xl:tw-pb-7">
      <h1 class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-extrabold tw-w-full tw-text-center md:tw-text-left">
        Settings
      </h1>
    </header>
    <v-card class="tw-bg-white !tw-p-0 tw-text-regular-text">
      <v-card-text class="!tw-p-0 tw-pb-6 !tw-text-base">
        <div class="tw-grid tw-grid-cols-12">
          <v-card class="tw-col-span-12 sm:tw-col-span-3 tw-border-x-0 tw-border-t-0 sm:tw-border-y-0 sm:tw-border-l-0 sm:tw-border-r tw-text-regular-text !tw-px-0 !tw-pt-0">
            <v-card-text class="!tw-p-0 !tw-text-base">
              <h3 class="tw-text-lg tw-font-black tw-p-6">Menu</h3>
              <ul class="tw-mt-3 tw-text-lg tw-font-semibold">
                <li>
                  <router-link to="/settings/profile" class="tw-block xl:tw-text-lg tw-font-semibold tw-py-3 tw-relative before:tw-content-[''] before:tw-absolute before:tw-h-full before:tw-top-0 before:-tw-left-0 before:tw-b-l before:tw-b-solid before:tw-w-[2px] tw-pl-6 [&.router-link-active]:tw-text-shyftoff-pink [&.router-link-active]:before:tw-bg-shyftoff-pink">
                    Profile
                  </router-link>
                </li>
                <li>
                  <router-link to="/settings/preferences" class="tw-block xl:tw-text-lg tw-font-semibold tw-py-3 tw-relative before:tw-content-[''] before:tw-absolute before:tw-h-full before:tw-top-0 before:-tw-left-0 before:tw-b-l before:tw-b-solid before:tw-w-[2px] tw-pl-6 [&.router-link-active]:tw-text-shyftoff-pink [&.router-link-active]:before:tw-bg-shyftoff-pink">
                    Preferences
                  </router-link>
                </li>
              </ul>
            </v-card-text>
          </v-card>
          <router-view></router-view>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>

::v-deep(.v-switch) {
  label {
    @apply tw-text-regular-text tw-opacity-100 #{!important};
  }
}

</style>
