import { defineStore } from 'pinia'
import type { Agent } from '@/types/agent'
import { reactive, ref } from 'vue'

export const useAgentStore = defineStore('agents', () => {
  const agentList = reactive<Agent[]>([])
  const agentCount = ref(0)
  function setAgents(agents: Agent[]) {
    agentList.push(...agents)
  }
  function resetAgents() {
    agentList.splice(0)
  }
  function setAgentCount(count: number) {
    agentCount.value = count
  }
  function modifyAgent({ agent_id, campaign_id, payload }: { agent_id: number, campaign_id: number, payload: Record<string, any> }) {
    const currentAgent = agentList.find((agent: Agent) => agent.id === agent_id)
    let currentCampaign
    if (currentAgent) {
      currentCampaign = currentAgent?.campaigns?.find((campaign: any) => campaign.id === campaign_id)
      if (currentCampaign) {
        currentCampaign.agent_status = payload.status
        currentCampaign.agent_status_updated_at = payload.updated_at
      }
    }
  }
  return { agentList, agentCount, setAgents, resetAgents, setAgentCount, modifyAgent }
})
