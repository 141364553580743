<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import CampaignCard from '@/components/campaigns/CampaignCard.vue'
import Pagination from '@/components/shared/Pagination.vue'
import useCampaigns from '@/composables/useCampaigns'
import { useCampaignStore } from '@/stores/campaigns'
import { useProfileStore } from '@/stores/profile'
import campaignDetails from '@/components/campaigns/CampaignDetails.vue'
import { useLayoutStore } from '@/stores/layout'

const currentPage = ref(1)
const loadingCampaigns = ref(true)
const { setLoadingState } = useLayoutStore()

const sortByItems = [
  { name: 'Base Agent Pay', sort:'high to low'},
  { name: 'Base Agent Pay', sort: 'low to high'},
  { name: 'Shift Days', sort: 'high to low' },
  { name: 'Shift Days', sort: 'low to high'},
  { name: 'Shift Hours', sort: 'high to low' },
  { name: 'Shift Hours', sort: 'low to high'}
]
let selectedSortBy = ref([])
//return campaign selector label from the selected campaign object
function itemProps(item: any) {
  return {
    title: item.name + ': ' + item.sort
  }
}

const { getUserCampaigns, fetchAllCampaigns, getUserCampaignApplications } = useCampaigns()
const campaignStore = useCampaignStore()
const profileStore = useProfileStore()
let totalPages = computed(() => {
  return Math.ceil(campaignStore.getCount/12)
})

async function getCampaignData() {
  await Promise.allSettled([fetchAllCampaigns({ limit: 12 }), getUserCampaignApplications()])
}

watch(() => profileStore.isLoading, async (newVal) => {
  if (!newVal) {
    await getCampaignData()
    loadingCampaigns.value = false
  }
})

onMounted( async () => {
  if (!profileStore.isLoading) {
    await getCampaignData()
    loadingCampaigns.value = false
    setLoadingState(false)
  }
})

async function pageFlipped(evt: any) {
  switch (evt) {
    case 'next':
      currentPage.value++
      break

    case 'previous':
      currentPage.value--
      break
  }
  await fetchAllCampaigns({ limit: 12, offset: 12 * (currentPage.value - 1) })
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

const drawerIsOpen = ref(false)
const currentCampaign = ref()
function openDrawer(evt: any) {
  drawerIsOpen.value = true
  currentCampaign.value = evt
}
</script>

<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-mx-auto tw-max-w-[1536px] tw-flex-grow">
    <header class="tw-flex tw-justify-center md:tw-justify-start tw-items-start tw-pb-6 xl:tw-pb-7 tw-space-y-3 tw-w-full ">
      <h1 class="tw-text-shyftoff-purple tw-text-2xl tw-font-black ">
        Open Campaigns
      </h1>
      <v-tooltip
        location="bottom"
        origin="auto"
        no-click-animation
        class="tw-m-0"
      >
        <template v-slot:activator="{ props }">
          <svg v-bind="props" class="!tw-mt-0 tw-cursor-pointer tw-fill-[#DADADA] hover:tw-fill-[#bca4e6]" width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM7.15 9.75H5.85V5.85H7.15V9.75ZM7.15 4.55H5.85V3.25H7.15V4.55Z"/>
          </svg>
        </template>

        <div class="tw-leading-[1.4]">
          <p>Below are current campaigns that are currently ramping to meet client demand. If no campaigns are listed, then no campaigns have open slots.</p>
          <p>We will send email communications about newly opened campaigns as well as posting them here.</p>
        </div>
      </v-tooltip>

    </header>
    <div >
      <!-- <div class="tw-grid tw-grid-cols-12 tw-mb-6 tw-gap-y-4 lg:tw-gap-x-6">
        <div class="tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-8">
          <VTextField
            label="Search"
            variant="outlined"
            density="comfortable"
            rounded="lg"
          >
            <template #prepend-inner>
              <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_305_171)">
                  <path d="M20.0208 18.0833H19.0004L18.6388 17.7346C19.9046 16.2621 20.6667 14.3504 20.6667 12.2708C20.6667 7.63375 16.9079 3.875 12.2708 3.875C7.63375 3.875 3.875 7.63375 3.875 12.2708C3.875 16.9079 7.63375 20.6667 12.2708 20.6667C14.3504 20.6667 16.2621 19.9046 17.7346 18.6388L18.0833 19.0004V20.0208L24.5417 26.4662L26.4662 24.5417L20.0208 18.0833ZM12.2708 18.0833C9.05458 18.0833 6.45833 15.4871 6.45833 12.2708C6.45833 9.05458 9.05458 6.45833 12.2708 6.45833C15.4871 6.45833 18.0833 9.05458 18.0833 12.2708C18.0833 15.4871 15.4871 18.0833 12.2708 18.0833Z" fill="#9E9BA4"/>
                </g>
                <defs>
                  <clipPath id="clip0_305_171">
                    <rect width="31" height="31" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </template>
          </VTextField>
        </div>
        <div class="tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4">
          <VSelect
            v-model="selectedSortBy"
            :items="sortByItems"
            :item-props="itemProps"
            label="Sort by"
            class="tw-flex-grow 2xl:tw-flex-grow-0 !tw-min-w-[310px]"
            variant="outlined"
            density="comfortable"
            rounded="lg"
          >
            <template #selection="{item}">
              <div class="tw-leading-[1.25] -tw-mb-2 -tw-mt-3 tw-pt-1">
                <b>{{ item.value.name + ': ' }}</b> {{ item.value.sort }}
              </div>
            </template>
          </VSelect>
        </div>
      </div> -->
      <div class="tw-grid tw-grid-cols-12 tw-gap-6">
        <template v-if="loadingCampaigns === true">
          <template v-for="campaign in 3" :key="campaign">
            <v-skeleton-loader
              class="tw-col-span-12 min-[1053px]:tw-col-span-6 min-[1705px]:tw-col-span-4 border"
              max-width="100%"
              type="card-avatar, actions">
            </v-skeleton-loader>
          </template>
        </template>
        <template v-if="campaignStore.getCount > 0 && loadingCampaigns === false">
          <template v-for="(campaign, index) in campaignStore.getCampaigns" :key="campaign">
            <CampaignCard :index="index" :campaign="campaign" @openDrawer="openDrawer" />
          </template>
        </template>
        <div v-if="campaignStore.getCount === 0 && loadingCampaigns === false" class="tw-col-span-12 tw-text-center tw-mt-8">
          <h5 class="tw-font-extrabold tw-text-lg tw-mb-6 tw-text-darker-light-text">
            No campaigns available.
          </h5>
          <img src="@/assets/images/empty-state--campaigns.svg" width="1355" height="349" class="tw-block !tw-min-h-[160px] sm:!tw-min-h-[270px] tw-max-w-full tw-relative tw-left-1/2 -tw-translate-x-1/2 tw-object-cover tw-scale-90">
        </div>
      </div>
      <Pagination v-if="totalPages > 1 && loadingCampaigns === false" :totalPages="totalPages" :currentPage="currentPage" @flipPage="pageFlipped" />
    </div>
    <transition>
      <component v-if="drawerIsOpen" :is="campaignDetails" :campaign="currentCampaign" @closeDrawer="drawerIsOpen = false" />
    </transition>
  </div>
</template>
<style lang="scss" scoped>
  .v-enter-active,
  .v-leave-active {
   transition: transform 0.2s ease-in-out;

  }

  .v-enter-from,
  .v-leave-to {
    @apply tw-translate-x-full;
  }
</style>
