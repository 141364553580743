import { defineStore, type StateTree } from 'pinia'
import type { Payment } from '@/types/payment'

export const usePaymentsStore = defineStore('payments', {
  state: () => ({
    payments: [] as Payment[]
  }),
  getters: {
    getPayments: (state: StateTree) => state.payments
  },
  actions: {
    setPayments(p: Payment[]) {
      this.payments = p
    }
  }

})
