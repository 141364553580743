<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps(['title', 'linkText', 'link'])
const alert = ref(true)
</script>

<template>
  <div>
    <v-alert
      v-model="alert"
      border="start"
      variant="flat"
      :title="props.title"
      color="#F7F6FF"
      border-color="#5723B4"
      class="tw-my-10 !tw-border-[#F7F6FF]">
        <slot></slot>
        <div>
          <a v-if="props.link && props.linkText" :href="props.link" target="_blank" class="tw-inline-block tw-rounded-lg tw-leading-[44px] tw-font-semibold tw-text-shyftoff-purple tw-h-11 tw-bg-white tw-px-6 tw-mt-4 hover:tw-bg-[#E7E5FC]">
            {{ props.linkText }}
          </a>
        </div>

    </v-alert>
  </div>
</template>

<style scoped lang="scss">
  ::v-deep(.v-alert) {
    @apply tw-rounded-lg tw-border tw-border-[#EAE8FF] tw-border-solid tw-border-l-0;
    .v-alert__border {
      @apply tw-opacity-100;
    }
    .v-alert-title {
      @apply tw-mb-6 #{!important};
    }
    .v-alert__content {
      @apply tw-text-[#403870] tw-leading-5;
      .v-alert-title {
        @apply tw-text-shyftoff-purple tw-font-extrabold tw-text-lg tw-mb-3;
      }
    }
    .v-alert__close {
      .v-btn__content {
        i {
          &:before {
            @apply tw-text-xl tw-text-[#403870];
          }
        }
      }
    }
    h3 {
      @apply tw-font-extrabold tw-mt-6;
      &:first-child {
        @apply tw-mt-0;
      }
    }
    ul {
      @apply tw-pl-4 tw-list-disc;
      li {
        @apply tw-my-3 tw-leading-6;
      }
    }
  }
</style>
