<script setup lang="ts">
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { onBeforeMount } from 'vue'
import { RouterView } from 'vue-router'
import { useProfileStore } from '@/stores/profile'

const router = useRouter()
const route = useRoute()
const profileStore = useProfileStore()

onBeforeMount(async () => {
  const { username, token }: any = JSON.parse(localStorage.getItem('auth') || '{"username": null, "token": null}')
  if (!profileStore.profile.shyftoff_id && username != null && token != null) {
    await router.isReady()
    if (['/login', '/signup'].includes(route.path)) {
      router.replace('/dashboard')
    }
    axios.defaults.headers.common = {
      Authorization: 'Bearer ' + token,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
    }
     profileStore.setInitialProfileData()
  }
})
</script>

<template>
  <component :is="$route.meta.layout">
    <RouterView />
  </component>
</template>

<style lang="scss">
  .v-card {
    &.v-card--variant-elevated {
      @apply tw-rounded-xl  tw-p-4 sm:tw-p-6;
      @apply tw-shadow-card #{!important};
    }
  }
  .v-field {
    .v-field__input {
      opacity: 1;
    }
  }
  .v-field__outline__start {
    flex-basis: 12px !important;
  }
  .v-input {
    .v-input__details {
      @apply tw-px-0;
    }
    .v-field {
      --v-field-padding-top: 2px !important;
      --v-field-padding-bottom: 10px !important;
    }
    .v-field__field {
      input {
        @apply tw-px-3 #{!important};
      }
      .v-label {
        @apply tw-mx-3 #{!important};
      }
      .v-field__input {
        @apply tw-pl-3 tw-items-center;
      }
    }
  }
  .v-pagination {
    .v-btn {
      &:not([aria-label]):disabled {
        @apply tw-bg-transparent tw-text-light-text tw-opacity-65 tw-text-lg #{!important};
      }
      &[aria-label$="page"]:not(:disabled) {
        @apply tw-text-shyftoff-purple #{!important};
      }
      &[aria-current="true"] {
        @apply tw-font-bold #{!important};
      }
    }
  }
  .v-skeleton-loader {
    .v-skeleton-loader__divider {
      @apply tw-h-[1px];

    }
    .v-skeleton-loader__table-row,
    .v-skeleton-loader__table-thead {
      .v-skeleton-loader__bone {
        @apply tw-mt-4 tw-hidden min-[435px]:tw-flex;
        &:first-child {
          @apply tw-block #{!important};
        }
        &:nth-of-type(2n+2) {
          @apply tw-hidden 2xl:tw-flex;
        }
      }
    }
  }
</style>
