import { ref } from 'vue'
import { useProfileStore } from '@/stores/profile'

export const useConvertToTime = function (n: number) {
  // Check sign of given number
  let sign: number|string = (n >= 0) ? 1 : -1

  // Set positive value of number of sign negative
  const number = n * sign

  // Separate the int from the decimal part
  const hour = Math.floor(number)
  let decpart = number - hour

  const min = 1 / 60
  // Round to nearest minute
  decpart = min * Math.round(decpart / min)

  let minute = Math.floor(decpart * 60) + ''

  // Add padding if need
  if (minute.length < 2) {
    minute = '0' + minute;
  }

  // Add Sign in final result
  sign = sign === 1 ? '' : '-'

  // Concate hours and minutes
  const time = ref(sign + hour + ':' + minute)



  return { floatToTime: time }
}

export const useConvert24HourTime = function(time: string | null | undefined) {
  // Check correct time format and split into components
  if (time != null) {
    const profileStore = useProfileStore()
    const t = time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/)
    if (t != null && t.length > 1) {
      const tt = t.slice(1)
      tt.pop()
      tt[5] = +tt[0] < 12 ? ' AM' : ' PM'
      tt[0] = (parseInt(tt[0]) % 12)?.toString() || '12'
      tt[0] = tt[0].length < 2 ? '0' + tt[0] : tt[0]
      return tt.join('')
    }
    return '--:--'
  }
  return '--:--'
}

export const displayTimezone = function () {
  const profileStore = useProfileStore()
  let timezone
  switch (profileStore.getProfile.agent?.timezone) {
    case 'us/eastern':
      timezone = 'EST'
      break
    case 'us/alaska':
      timezone = 'AST'
      break
    case 'us/arizona':
      timezone = 'MST'
      break
    case 'us/central':
      timezone = 'CST'
      break
    case 'us/hawaii':
      timezone = 'HST'
      break
    case 'us/mountain':
      timezone = 'MT'
      break
    case 'us/pacific':
      timezone = 'PST'
      break
    default:
      timezone = ''
      break
  }
  return timezone
}

type ConvertTypes = 'hours' | 'date' | 'time' | 'dateTime'

export const convertDateTimeToUsersTimezone = (dateTime: string, type: ConvertTypes) => {
  const timezoneOffset: Record<string, number> = {
    'us/arizona': -3,
    'us/alaska': -4,
    'us/central': -1,
    'us/eastern': 0,
    'us/hawaii': -6,
    'us/mountain': -2,
    'us/pacific': -3
  }

  const profileStore = useProfileStore();
  const userTimezone = profileStore.getProfile?.agent?.timezone || 'us/eastern'

  if (userTimezone === 'us/eastern') {
    const date = new Date(dateTime)
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear()

    if (type === 'date') {
      return `${month}/${day}/${year}`
    }
    
    if (type === 'dateTime')  {
      const time = new Date(dateTime).toLocaleTimeString()
      return `${month}/${day}/${year} ${time}`
    } else {
      return dateTime
    }
  }

  if (dateTime) {
    if (type === 'hours') {
      const timeValues = dateTime.split(':');
      const d = new Date();

      d.setHours(parseInt(timeValues[0]));
      d.setMinutes(parseInt(timeValues[1]));
      d.setSeconds(parseInt(timeValues[2]));
      d.setHours(d.getHours() + timezoneOffset[userTimezone]);

      return d.toLocaleTimeString();
    } else {
      const d = new Date(dateTime);
      d.setHours(d.getHours() + timezoneOffset[userTimezone]);

      const date = new Date(d)
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
  
      if (type === 'date') {
        return `${month}/${day}/${year}`
      }
  
      if (type === 'dateTime') {
        const time = new Date(d).toLocaleTimeString()
        return `${month}/${day}/${year} ${time}`
      }
    }
  }

  return '';
}
