export default function useLocalStorage() {
  const userRoles: string = localStorage.getItem('roles') || ''

  function hasPermission(roles: string[]): boolean {
    if (roles.includes('all')) return true
    if (roles.length && !userRoles.includes('Admins')) return true
    return roles.some((role) => userRoles.includes(role))
  }

  return {
    hasPermission
  }
}
