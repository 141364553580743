<script setup lang="ts">
import Editor from '@tinymce/tinymce-vue'


const emit = defineEmits(['update:modelValue'])
const props = defineProps(['modelValue'])

function updateValue(val: string) {
  emit('update:modelValue', val)
}
const apiKey = import.meta.env.VITE_TINYMCE_TOKEN

</script>

<template>
  <div id="wizzywig">
    <Editor
      :api-key="apiKey"
      initial-value="Welcome to TinyMCE!"
      @update:model-value="updateValue"
      :model-value="props.modelValue"
      :init="{
        plugins: 'anchor autolink charmap codesample emoticons image link lists media table visualblocks wordcount',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
      }"
    />
  </div>

</template>
<style>
  .tox.tox-tinymce-aux {
    z-index: 3000 !important;
  }
</style>
<style scoped lang="scss">
  @media (min-width: 1024px) {
  #wizzywig {
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 100%;
  }
  :deep(.tox.tox-tinymce) {
    width: 100% !important;
    border: none;
  }
}
</style>
