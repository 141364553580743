import { defineStore, type StateTree } from 'pinia'
import type { Feedback } from '@/types/feedback'

export const useFeedbackStore = defineStore('feedback', {
  state: () => ({
    feedbackList: [] as Feedback[],
    unacknowledged: 0 as number
  }),
  getters: {
    getFeedback: (state: StateTree) => state.feedbackList,
    getUnacknowledged: (state: StateTree) => state.unacknowledged,
  },
  actions: {
    setFeedbackList(f: Feedback[]) {
      this.feedbackList = f
    },
    setUnacknowledged(l: number) {
      this.unacknowledged = l
    },
    decreaseUnacknowledged() {
      this.unacknowledged --
    },
    updateFeedbackInTheList(updatedFeedback: Feedback) {
      const index = this.feedbackList.findIndex(({ id }) => id === updatedFeedback.id)
      this.feedbackList[index] = updatedFeedback      
    }
  }

})
