<script setup lang="ts">
import { onBeforeMount, computed, ref } from 'vue'
import CampaignDetails from '@/components/campaigns/CampaignDetails.vue'
import { useRoute } from 'vue-router'
import useCampaigns from '@/composables/useCampaigns'
import { useProfileStore } from '@/stores/profile'
import { useCampaignStore } from '@/stores/campaigns'
import { type Campaign } from '@/types/campaign'

const route = useRoute()
const profileStore = useProfileStore()
const { getAgentCampaignDetails, campaignDetails, fetchAllCampaigns } = useCampaigns()
const campaignStore = useCampaignStore()

onBeforeMount(async () => {
  if (profileStore.isAgentRole) {
    await getAgentCampaignDetails(route.params.id as string)
  } else {
    // await getAdminCampaignDetails(route.params.id as string)
    await fetchAllCampaigns({ offset: 0, limit: 100, with_count: true })
    campaignDetails.value = campaignStore.getCampaigns.find((campaign: Campaign) => campaign.id === parseInt(route.params.id as string))
  }
})
</script>
<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-mx-auto tw-max-w-[1536px] tw-flex-grow">
    <header class="tw-flex tw-items-center tw-pb-6 xl:tw-pb-7">
      <h1 class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-black tw-w-full tw-text-center md:tw-text-left">
        {{ campaignDetails?.name  }}
      </h1>
    </header>
    <CampaignDetails v-if="campaignDetails" :campaign="campaignDetails"></CampaignDetails>
  </div>
</template>
<style lang="scss" scoped>
  .v-card {
    @apply tw-static tw-border tw-border-solid tw-h-[calc(100vh_-_84px)] tw-min-w-full #{!important};
  }
  .v-card {
    &.v-card--variant-elevated {
      @apply tw-pt-0;
    }
  }
  .v-dialog {
    .v-card {
      .v-card-text,
      .v-card-actions,
      .v-card-title {
        @apply tw-p-0 #{!important};
      }
    }
  }
  ::v-deep(.v-selection-control-group) {
    @apply tw-flex-row tw-gap-x-6 #{!important};
    .v-radio {
      @apply tw-border tw-border-grey-border tw-border-solid tw-rounded-lg tw-flex-row-reverse;
      &.v-selection-control--dirty {
        @apply tw-border-shyftoff-purple;
        .v-label {
          @apply tw-text-shyftoff-purple;
        }
        .v-selection-control__input {
        i {
            @apply tw-text-shyftoff-purple;
          }
        }
      }
      .v-label {
        @apply tw-font-extrabold tw-opacity-100 tw-pl-4;
      }
      .v-selection-control__input {
        i {
          @apply tw-text-grey-border;
        }
      }
    }
  }
  ::v-deep(.v-file-input) {
    .v-field {
      @apply tw-h-[150px] tw-border-[#D4D2D8];
      &.v-field--focused {
        .v-field__outline {
          .v-field__outline__start,
          .v-field__outline__end {
            @apply tw-border;
          }
          .v-field__outline__start {
            @apply tw-border-r-0;
          }
          .v-field__outline__end {
            @apply tw-border-l-0;
          }
        }
      }
      .v-field__field {
        input {
          @apply tw-cursor-pointer;
        }
      }
      .v-field__outline {
        .v-field__outline__start,
        .v-field__outline__end {
          @apply tw-border-dashed tw-border-shyftoff-purple;
        }
      }
    }
  }
  ::v-deep(.v-radio-group) {
    .v-input__control {
      @apply tw-basis-full;
    }
  }
</style>
