import { defineStore, type StateTree } from 'pinia'

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    drawerIsOpen: false as boolean,
    loadingState: false as boolean
  }),
  getters: {
    getDrawerIsOpen: (state: StateTree) => state.drawerIsOpen,
    appIsLoading: (state: StateTree) => state.loadingState,
  },
  actions: {
    setDrawerState(n:boolean) {
      this.drawerIsOpen = n
    },
    setLoadingState(n:boolean) {
      this.loadingState = n
    },

  }
})
