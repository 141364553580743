<script setup lang="ts">
import { onBeforeMount, computed, ref, toRaw, watch, nextTick, toRefs } from 'vue'
import useUser from '@/composables/useUser'
import useAgents from '@/composables/useAgents'
import type { Stats } from '@/types/stats'
import StatsChart from '@/components/profile/StatsChart.vue'
import { useProfileStore } from '@/stores/profile'
import PriorityBoarding from '@/components/dashboard/PriorityBoarding.vue'
import useHelpers from '@/composables/useHelpers'
import { useLayoutStore } from '@/stores/layout'
import { useAgentStore } from '@/stores/agents'

const {
  userStats,
  userDailyStats,
  userDailyByCampaignStats,
  userStatsByCampaign,
  getUserStats,
  getUserDailyStats,
  getUserDailyByCampaignStats,
  getUserCampaignStats
} = useUser()

const profileStore = useProfileStore()
const { getDrawerIsOpen } = toRefs(useLayoutStore())
const { setLoadingState } = useLayoutStore()
const {
  currentAgent,
  initialAgentsList,
  numberOfPages,
  currentPage,
  fetchAgents,
  fetchAgentStats,
  searchAgent
} = useAgents()
const { agentList } = toRefs(useAgentStore())
const { resetAgents, setAgents } = useAgentStore()
const { debounce, dateWithoutTimezone } = useHelpers()

const selectedCampaign = ref({ campaign: { name: 'All campaigns' } })
const initialSelectedCampaignStats = ref<any[]>([])
const selectedCampaignStats = ref<any[]>([])
const updateChart = ref(0)
const loadingStats = ref(true)
const hidePBboarding = ref<boolean | undefined>(false)
const loadingAgents = ref(false)
const searchTerm = ref<string>()

function buildStats(statsObject: Stats[]) {
  const computedStats = []
  const startDate = statsObject?.length ? new Date(statsObject[0]?.stats_date) : null
  const endDate = statsObject?.length ? new Date(statsObject[statsObject?.length - 1]?.stats_date) : null
  let currentDate = startDate != null ? new Date(startDate) : null
  if (startDate != null && endDate != null && currentDate != null) {
    while (currentDate <= endDate) {
      const currentDateStr = currentDate.toISOString().split('T')[0]
      const existingObject = statsObject.find((obj: Stats) => obj.stats_date === currentDateStr)
      if (existingObject) {
        computedStats.push(existingObject)
      } else {
        // Add a placeholder object if the date is missing
        computedStats.push({
          reliability: 0,
          scheduled_hours: 0,
          productive_hours: 0,
          unscheduled_hours: 0,
          stats_date: currentDateStr
        })
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return computedStats
  }
}
function getDayName(date: Date, locale: string = 'en-US') {
  const currentDate = new Date(date)
  if (isNaN(currentDate as any)) {
    return ''
  }
  const dateWithoutTimezone = new Date(currentDate.toISOString().slice(0, -1))
  return dateWithoutTimezone.toLocaleDateString(locale, {day: 'numeric', month: 'short'})
}
function itemProps(item: any) {
  return {
    title: item.campaign.name
  }
}
function agentProps(item: any) {
  return {
    title: item.name
  }
}
async function changedSelectedCampaign({ campaign }: any) {
  updateChart.value++
  // hidePBboarding.value = false
  if (campaign.name === 'All campaigns') {
    selectedCampaignStats.value = JSON.parse(JSON.stringify(toRaw(initialSelectedCampaignStats.value)))
  } else {
    const campaignStats = JSON.parse(JSON.stringify(toRaw(userDailyByCampaignStats.value))).filter((stats: Stats) => {
      return (
        stats.campaign != null &&
        typeof stats.campaign === 'object' &&
        'id' in stats.campaign &&
        stats.campaign['id'] === campaign.id
      )}
    )
    selectedCampaignStats.value = buildStats(campaignStats) ?? []
  }
}
async function changedSelectedAgent() {
  loadingStats.value = true
  await setCurrentAgentCampaignStats()
  updateChart.value++
  selectedCampaign.value = { campaign: { name: 'All campaigns' } }
  loadingStats.value = false
}
const campaignsSummary = computed(() => {
  if (selectedCampaign.value) {
    if (selectedCampaign.value.campaign.name === 'All campaigns') {
      return Object.entries(userStats.value).length ? userStats.value : null
    }
    return userStatsByCampaign.value.find((stats: any) =>
      stats.campaign.id &&
      'id' in selectedCampaign.value.campaign &&
      stats.campaign.id === selectedCampaign.value.campaign.id
    )
  }
  return null
})
const chartLabels = computed(() => {
  const statsDates = selectedCampaignStats.value ? selectedCampaignStats.value.map( (stats) => getDayName(stats.stats_date) ?? '') : null
  return [...new Set(statsDates)]
})
const convertedScheduledHours = computed(() => {
  if (
    'scheduled_hours' in selectedCampaign.value &&
    typeof selectedCampaign.value.scheduled_hours === 'number'
  ) {
    return selectedCampaign.value.scheduled_hours
  }
  return null
})
//convert productivity value to actual time in hours
const convertedProductiveHours = computed(() => {
  if (
    selectedCampaign.value &&
    'productive_hours' in selectedCampaign.value &&
    typeof selectedCampaign.value.productive_hours === 'number'
  ) {
    return selectedCampaign.value.productive_hours?.toFixed(2)
  }
  return null
})
const reliabilityChartData = computed(() =>
  selectedCampaignStats.value ? selectedCampaignStats.value.map((stats: Stats, index: any) => {
        return {
          x: chartLabels.value[index] || '',
          y: stats.reliability ? (stats.reliability * 100).toFixed(2) : 0,
          goals: [
            {
              name: 'Expected',
              value: 98,
              strokeHeight: 4,
              strokeWidth: 8,
              // strokeLineCap: 'round',
              strokeColor: loadingStats.value || loadingAgents.value ? '#DADADA' : '#F282CD'
            }
          ]
        }
  }) : []
)
const productivityChartData = computed(() =>
  selectedCampaignStats.value ? selectedCampaignStats.value.map((stats: Stats, index: any) => {
    return {
      x: chartLabels.value[index] || '',
      y: stats.productive_hours ? stats.productive_hours.toFixed(2) : 0,
    }
  }) : []
)
const emptyChartState = computed(() => (
    (!campaignsSummary.value ||
    (
        'productive_hours' in campaignsSummary.value &&
        ( campaignsSummary.value.productive_hours === 0 || campaignsSummary.value.productive_hours == null ) &&
        'reliability' in campaignsSummary.value &&
        ( campaignsSummary.value.reliability === 0 || campaignsSummary.value.reliability == null )
    ) )) && loadingStats.value === false
)
const productivityChartSeries = computed(() =>
  reliabilityChartData.value && reliabilityChartData.value.length ?
    [
      {
        name: 'Productive hours',
        data: productivityChartData.value
      }
    ] : [{"name":"Productive hours","data":[{"x":"Nov 3","y":"10.44"},{"x":"Nov 4","y":0},{"x":"Nov 5","y":"4.91"},{"x":"Nov 6","y":"8.63"},{"x":"Nov 7","y":"4.48"},{"x":"Nov 8","y":"8.63"},{"x":"Nov 9","y":"10.43"},{"x":"Nov 10","y":"11.08"},{"x":"Nov 12","y":"5.03"},{"x":"Nov 13","y":"10.88"},{"x":"Nov 14","y":"9.25"},{"x":"Nov 15","y":"3.86"},{"x":"Nov 16","y":"8.92"},{"x":"Nov 17","y":0}]}]
)
const reliabilityChartSeries = computed(() =>
  reliabilityChartData.value && reliabilityChartData.value.length ?
    [
      {
        name: 'Reliability',
        data: reliabilityChartData.value
      }
    ] : [{"name":"Reliability","data":[{"x":"Nov 3","y":"94.84","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 4","y":0,"goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 5","y":"89.29","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 6","y":"71.91","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 7","y":"44.80","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 8","y":"90.86","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 9","y":"94.80","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 10","y":"92.94","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 12","y":"83.79","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 13","y":"93.10","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 14","y":"90.87","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 15","y":"84.67","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 16","y":"88.85","goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]},{"x":"Nov 17","y":0,"goals":[{"name":"Expected","value":98,"strokeHeight":4,"strokeWidth":8,"strokeColor":"#F282CD"}]}]}]
)
async function setCurrentCampaignStats() {
  try {
    if (profileStore.getProfile != null) {
      loadingStats.value = true
      await Promise.allSettled([getUserDailyStats(profileStore.getProfile.agent?.id), getUserDailyByCampaignStats(), getUserStats(), getUserCampaignStats()])
      const dailyStats = toRaw(userDailyStats.value)
      initialSelectedCampaignStats.value = buildStats(dailyStats) ?? []
      selectedCampaignStats.value = JSON.parse(JSON.stringify( initialSelectedCampaignStats.value))
    }
    await nextTick()
    loadingStats.value = false
  } catch (error) {
    console.log(error)
    await nextTick()
    loadingStats.value = false
  }
}
async function setCurrentAgentCampaignStats() {
  if (currentAgent.value != null && typeof currentAgent.value.id === 'number') {
    try {
      const response: { statsByCampaign: any; dailyStatsByCampaign: any; agentStats: any; dailyStats: any; } | undefined = await fetchAgentStats(currentAgent.value.id)
      const responseRawData = toRaw(response?.dailyStats.value?.data)
      initialSelectedCampaignStats.value = buildStats(responseRawData) ?? []
      userStatsByCampaign.value = [{ campaign: { name: 'All campaigns' } }]
      selectedCampaignStats.value = JSON.parse(JSON.stringify(initialSelectedCampaignStats.value))

      userDailyByCampaignStats.value = response?.dailyStatsByCampaign.value.data
      userStats.value = response?.agentStats.value?.data
      if (response?.statsByCampaign?.value?.data != null) {
        userStatsByCampaign.value.push(...response.statsByCampaign.value.data)
      }
      await nextTick()
      loadingStats.value = false
      setLoadingState(false)
    } catch (error: any) {
      console.error(error)
      await nextTick()
      loadingStats.value = false
      setLoadingState(false)
    }
  }
}
async function onIntersect() {
  if (currentPage.value < numberOfPages.value && !loadingAgents.value) {
    loadingAgents.value = true
    currentPage.value++
    await fetchAgents({ with_count: true, items_per_page: 30 })
    loadingAgents.value = false
  }
}
async function callSearchApi(val: string) {

  searchTerm.value = val
  if (val === '' || val == null || val === currentAgent.value?.name) {
    resetAgents()
    setAgents(JSON.parse(JSON.stringify(initialAgentsList.value)))
  }
  debounce( () => {
    searchAgent(val)
  }, 300)
}


watch(() => profileStore.getProfile, async () => {
  if (profileStore.isAgentRole) {
    loadingStats.value = true
    setCurrentCampaignStats()
  } else {
    loadingStats.value = true
    await fetchAgents({ with_count: true, items_per_page: 30 })
    await setCurrentAgentCampaignStats()
  }
})

onBeforeMount(async () => {

  loadingStats.value = true
  if (profileStore.isAgentRole) {

    loadingStats.value = true
    setCurrentCampaignStats()
  } else if (profileStore.isAgentRole === false) {
    loadingStats.value = true
    resetAgents()
    await fetchAgents({ with_count: true, items_per_page: 30 })
    await setCurrentAgentCampaignStats()
  }
  setLoadingState(false)
})

</script>

<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-mx-auto tw-max-w-[1536px] tw-flex-grow">
    <header class="tw-flex tw-items-center tw-mb-0">
      <h1 class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-black tw-w-full tw-text-center md:tw-text-left">
        Dashboard
      </h1>
    </header>
    <div>
      <div class="tw-flex tw-flex-col tw-gap-6 md:tw-gap-10">
        <div v-if="!loadingStats">
          <ul v-if="campaignsSummary" class="tw-pt-6 xl:tw-pt-7 tw-grid tw-grid-cols-12 tw-justify-items-end lg:tw-gap-x-6 tw-gap-y-4 tw-text-center">
            <li v-if="'scheduled_hours' in campaignsSummary" class="tw-bg-[#F6F0FF] tw-flex tw-flex-col tw-items-start tw-rounded-lg tw-justify-start tw-col-span-12 lg:tw-col-span-3 tw-py-3 tw-px-6 tw-w-full tw-space-y-1" :class="{ 'lg:tw-col-span-6 2xl:tw-col-span-3': getDrawerIsOpen }">
              <span class="tw-text-sm xl:tw-text-sm tw-grow-0 tw-opacity-70">
                Scheduled hours
              </span>
              <span class="tw-font-black tw-text-xl xl:tw-text-2xl tw-grow-0 tw-tracking-wide">
                {{ campaignsSummary.scheduled_hours ?? 0 }}
              </span>
            </li>
            <li v-if="'productive_hours' in campaignsSummary" class="tw-bg-[#F3F2FF] tw-flex tw-flex-col tw-items-start tw-rounded-lg tw-justify-start tw-col-span-12 lg:tw-col-span-3 tw-py-3 tw-px-6 tw-w-full tw-space-y-1" :class="{ 'lg:tw-col-span-6 2xl:tw-col-span-3': getDrawerIsOpen }">
              <span class="tw-text-sm xl:tw-text-sm tw-grow-0 tw-opacity-70">
                Productive hours
              </span>
              <span class="tw-font-black tw-text-xl xl:tw-text-2xl tw-grow-0 tw-tracking-wide">
                {{ campaignsSummary.productive_hours ? (campaignsSummary.productive_hours as number).toFixed(2) : 0 }}
              </span
              >
            </li>
            <li v-if="'unscheduled_hours' in campaignsSummary" class="tw-bg-[#FFEFF9] tw-flex tw-flex-col tw-items-start tw-rounded-lg tw-justify-start tw-col-span-12 lg:tw-col-span-3 tw-py-3 tw-px-6 tw-w-full tw-space-y-1" :class="{ 'lg:tw-col-span-6 2xl:tw-col-span-3': getDrawerIsOpen }">
              <span class="tw-text-sm xl:tw-text-sm tw-grow-0 tw-opacity-70">
                Unscheduled hours
              </span>
              <span class="tw-font-black tw-text-xl xl:tw-text-2xl tw-grow-0 tw-tracking-wide">
                {{ campaignsSummary.unscheduled_hours ? (campaignsSummary.unscheduled_hours as number).toFixed(2) : 0 }}
              </span>
            </li>
            <li v-if="'reliability' in campaignsSummary" class="tw-bg-[#FFF0F1] tw-flex tw-flex-col tw-items-start tw-rounded-lg tw-justify-start tw-col-span-12 lg:tw-col-span-3 tw-py-3 tw-px-6 tw-w-full tw-space-y-1" :class="{ 'lg:tw-col-span-6 2xl:tw-col-span-3': getDrawerIsOpen }">
              <span class="tw-text-sm xl:tw-text-sm tw-grow-0 tw-opacity-70">
                Reliability
              </span>
              <span class="tw-font-black tw-text-xl xl:tw-text-2xl tw-grow-0 tw-tracking-wide">
                {{
                  campaignsSummary &&
                  campaignsSummary.reliability != null &&
                  typeof campaignsSummary.reliability === 'number'
                    ?
                    (campaignsSummary.reliability * 100).toFixed(0) + '%':
                    '-'
                }}
              </span>
            </li>
          </ul>
        </div>
        <div v-else class="tw-pt-6 xl:tw-pt-7">
          <div class="tw-grid tw-grid-cols-12 lg:tw-gap-x-6 tw-gap-y-4 tw-text-center">
            <div class="tw-col-span-12 lg:tw-col-span-3">
              <v-skeleton-loader class="tw-h-[80px] tw-w-full tw-rounded-lg"></v-skeleton-loader>
            </div>
            <div class="tw-col-span-12 lg:tw-col-span-3">
              <v-skeleton-loader class="tw-h-[80px] tw-w-full tw-rounded-lg"></v-skeleton-loader>
            </div>
            <div class="tw-col-span-12 lg:tw-col-span-3">
              <v-skeleton-loader class="tw-h-[80px] tw-w-full tw-rounded-lg"></v-skeleton-loader>
            </div>
            <div class="tw-col-span-12 lg:tw-col-span-3">
              <v-skeleton-loader class="tw-h-[80px] tw-w-full tw-rounded-lg"></v-skeleton-loader>
            </div>
          </div>
        </div>
        <v-card class="tw-bg-white tw-text-regular-text tw-pb-0">
          <div>
            <div class="tw-grid tw-grid-cols-12 tw-gap-6">
              <div
                class="tw-col-span-12 min-[1799px]:tw-col-span-6 tw-gap-x-6 tw-space-y-4 tw-flex tw-flex-col sm:tw-flex-row sm:tw-space-y-0 tw-justify-between tw-items-center tw-min-w-[320px]"
                :class="{'lg:!tw-col-span-8': !profileStore.isAgentRole, '2xl:tw-col-span-3': profileStore.isAgentRole}">
                  <v-autocomplete
                    v-if="!profileStore.isAgentRole"
                    v-model="currentAgent"
                    rounded="lg"
                    @update:modelValue="changedSelectedAgent"
                    @update:search="callSearchApi"
                    :items="agentList"
                    :item-props="agentProps"
                    label="Agent"
                    class="tw-grow tw-w-full"
                    variant="outlined"
                    :disabled="loadingStats"
                    density="compact">
                      <template v-slot:append-item>
                        <div v-if="currentAgent?.name === searchTerm ||  searchTerm == null || searchTerm === ''" v-intersect.quiet="onIntersect" class="tw-text-light-text tw-p-4">
                           Loading more...
                        </div>
                      </template>
                      <template #selection="{item}">
                        <div class="tw-flex tw-flex-col tw-leading-[1.25] -tw-mb-2 -tw-mt-3 tw-pt-1">
                          {{ item?.title ?? '' }}
                        </div>
                      </template>
                  </v-autocomplete>
                  <VSelect
                    v-model="selectedCampaign"
                    rounded="lg"
                    @update:modelValue="changedSelectedCampaign"
                    :items="userStatsByCampaign"
                    :item-props="itemProps"
                    label="Campaign"
                    class="tw-grow tw-w-full"
                    :class="{'2xl:!tw-max-w-[96%] min-[1799px]:!tw-max-w-[47%]': profileStore.isAgentRole}"
                    variant="outlined"
                    :disabled="loadingStats"
                    density="compact">
                      <template #selection="{item}">
                        <div class="tw-flex tw-flex-col tw-leading-[1.25] -tw-mb-2 -tw-mt-3 tw-pt-[0.35rem]">
                          {{ !!item.title ? item.title : '-'  }}
                          <small v-if="chartLabels && chartLabels.length" class="tw-text-gray-500 tw-leading-none">
                            Reference period: {{ chartLabels[0] }} {{ chartLabels.length > 1 ? '- ' + chartLabels[chartLabels.length - 1] : '' }}</small>
                        </div>
                      </template>
                  </VSelect>
              </div>
            </div>
          </div>
          <div v-if="!emptyChartState" class="tw-mt-12 ">
            <div>
              <ul class="tw-flex tw-items-center tw-space-x-8">
                <li v-if="!loadingStats" class="before:tw-content-[''] before:tw-inline-block before:tw-w-8 before:tw-h-4 before:tw-bg-[#8C8CE8] before:tw-rounded tw-align-middle">
                  <span class="tw-align-middle tw-ml-2">Productive hours</span>
                </li>
                <li v-else>
                  <v-skeleton-loader class="tw-h-[16px] tw-min-w-[120px] tw-w-full !tw-rounded-md"></v-skeleton-loader>
                </li>
                <li v-if="!loadingStats" class="before:tw-content-[''] before:tw-inline-block before:tw-w-8 before:tw-h-4 before:tw-bg-[#EB74C4] before:tw-rounded tw-align-middle">
                  <span class="tw-align-middle tw-ml-2">Reliability</span>
                </li>
                <li v-else>
                  <v-skeleton-loader class="tw-h-[16px] tw-min-w-[120px] tw-w-full !tw-rounded-md"></v-skeleton-loader>
                </li>
              </ul>
            </div>
            <div v-if="selectedCampaign && selectedCampaign.campaign.name !== 'All campaigns'" class="tw-mt-6">
              <div class="tw-col-span-12 2xl:tw-col-span-9 min-[1799px]:tw-col-span-6 tw-grow-0" :class="{'2xl:!tw-col-span-6 min-[1799px]:!tw-col-span-7': !profileStore.isAgentRole}">
                <ul class="tw-grid tw-grid-cols-12 tw-justify-items-stretch tw-gap-y-4 xl:tw-gap-y-0 xl:tw-gap-x-6 tw-text-center tw-h-full" :class="{ 'xl:tw-gap-y-4 min-[1680px]:!tw-gap-y-0': getDrawerIsOpen }">
                  <li v-if="'scheduled_hours' in selectedCampaign" class="tw-bg-[linear-gradient(90deg,_#575EE7_3.71%,_#EF74C0_95.38%)] tw-rounded-lg tw-col-span-12 xl:tw-col-span-3 tw-p-[1px] w-full" :class="{ 'xl:!tw-col-span-6 min-[1680px]:!tw-col-span-3': getDrawerIsOpen }">
                    <div class="tw-bg-white tw-flex tw-items-center tw-justify-between tw-leading-[48px] tw-w-full tw-rounded-[0.45rem] tw-px-3">
                      <span class="tw-text-[#3f3c91]">
                        Scheduled hours
                      </span>
                      <span class="tw-font-black tw-text-lg xl:tw-text-xl tw-grow-0 tw-text-shyftoff-purple tw-tracking-wide">
                        {{ convertedScheduledHours }}
                      </span>
                    </div>
                  </li>
                  <li v-if="'productive_hours' in selectedCampaign" class="tw-bg-[linear-gradient(90deg,_#575EE7_3.71%,_#EF74C0_95.38%)] tw-rounded-lg tw-col-span-12 xl:tw-col-span-3 tw-p-[1px] w-full" :class="{ 'xl:!tw-col-span-6 min-[1680px]:!tw-col-span-3': getDrawerIsOpen }">
                    <div class="tw-bg-white tw-flex tw-items-center tw-justify-between tw-leading-[48px] tw-w-full tw-rounded-[0.45rem] tw-px-3">
                      <span class="tw-text-[#3f3c91]">
                        Productive hours
                      </span>
                      <span class="tw-font-black tw-text-lg xl:tw-text-xl tw-grow-0 tw-text-shyftoff-purple tw-tracking-wide">
                        {{ convertedProductiveHours }}
                      </span>
                    </div>
                  </li>
                  <li v-if="'lifetime_hours' in selectedCampaign" class="tw-bg-[linear-gradient(90deg,_#575EE7_3.71%,_#EF74C0_95.38%)] tw-rounded-lg tw-col-span-12 xl:tw-col-span-3 tw-p-[1px] w-full" :class="{ 'xl:!tw-col-span-6 min-[1680px]:!tw-col-span-3': getDrawerIsOpen }">
                    <div class="tw-bg-white tw-flex tw-items-center tw-justify-between tw-leading-[48px] tw-w-full tw-rounded-[0.45rem] tw-px-3">
                      <span class="tw-text-[#3f3c91]">
                        Lifetime Hours
                      </span>
                      <span class="tw-font-black tw-text-lg xl:tw-text-xl tw-grow-0 tw-text-shyftoff-purple tw-tracking-wide">
                        {{
                          selectedCampaign.lifetime_hours != null &&
                          typeof selectedCampaign.lifetime_hours === 'number'
                            ?
                            selectedCampaign.lifetime_hours?.toFixed(2):
                            '-'
                        }}
                      </span>
                    </div>
                  </li>
                  <li v-if="'reliability' in selectedCampaign" class="tw-bg-[linear-gradient(90deg,_#575EE7_3.71%,_#EF74C0_95.38%)] tw-rounded-lg tw-col-span-12 xl:tw-col-span-3 tw-p-[1px] w-full" :class="{ 'xl:!tw-col-span-6 min-[1680px]:!tw-col-span-3': getDrawerIsOpen }">
                    <div class="tw-bg-white tw-flex tw-items-center tw-justify-between tw-leading-[48px] tw-w-full tw-rounded-[0.45rem] tw-px-3">
                      <span class="tw-text-[#3f3c91]">
                        Reliability
                      </span>
                      <span class="tw-font-black tw-text-lg xl:tw-text-xl tw-grow-0 tw-text-shyftoff-purple tw-tracking-wide">
                        {{
                          selectedCampaign.reliability != null &&
                          typeof selectedCampaign.reliability === 'number'
                            ?
                            (selectedCampaign.reliability * 100).toFixed(0) + '%':
                            '-'
                        }}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tw-grid tw-grid-cols-12 tw-grid-flow-dense tw-gap-2 tw-overflow-hidden tw-overflow-x-auto">
              <div class="tw-col-span-12 min-[1600px]:tw-col-span-6 tw-h-[350px] md:tw-h-[430px] tw-min-w-[520px]" :class="{ 'min-[1600px]:!tw-col-span-12 min-[1750px]:!tw-col-span-6': getDrawerIsOpen }">
                <StatsChart
                  type="bar"
                  :key="updateChart"
                  :chartSeries="productivityChartSeries"
                  :chartColor="!loadingStats ? '#8C8CE8' : '#DADADA'"
                />
              </div>
              <div class="tw-col-span-12 min-[1600px]:tw-col-span-6 tw-h-[350px] md:tw-h-[430px] tw-min-w-[520px]" :class="{ 'min-[1600px]:!tw-col-span-12 min-[1750px]:!tw-col-span-6': getDrawerIsOpen }">
                <StatsChart
                  type="bar"
                  :key="updateChart"
                  :chartSeries="reliabilityChartSeries"
                  :chartColor="!loadingStats ? '#EB74C4' : '#DADADA'"
                />
              </div>
            </div>
          </div>
          <div v-else-if="!loadingStats" class="tw-text-center tw-mt-20 tw-mb-8 tw-col-span-12">
            <h5 class="tw-font-extrabold tw-text-lg tw-mb-6 tw-text-darker-light-text">
              No data available.
            </h5>
            <img src="@/assets/images/empty-state--charts.svg" class="tw-block !tw-min-h-[160px] sm:!tw-min-h-[270px] tw-max-w-full tw-relative tw-left-1/2 -tw-translate-x-1/2 tw-object-cover tw-scale-90" alt="">
          </div>
          <!-- charts -->
          <div v-if="!emptyChartState && 'id' in selectedCampaign.campaign && !hidePBboarding" class="tw-mt-9">
              <h3 class="tw-font-black tw-text-lg">
                Priority Boarding
              </h3>
              <priority-boarding
                class="tw-mt-4"
                @PBboardingError="hidePBboarding = true"
                :agent="currentAgent ?? profileStore.profile?.agent"
                :campaignID="selectedCampaign.campaign.id">
              </priority-boarding>
          </div>
        </v-card>
        <!-- second card -->
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">


.stats-number {
  @apply tw-text-lg tw-font-bold tw-text-neutral-600;
}
</style>
