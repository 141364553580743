<script setup lang="ts">
import { onBeforeMount, ref, watch, onMounted } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import useAgents from '@/composables/useAgents'
import { useFeedbackStore } from '@/stores/feedback'
import type { Feedback } from '@/types/feedback'
import { useLayoutStore } from '@/stores/layout'

const router = useRouter()
const route = useRoute()

/**
 * stores & composables
 */
const { fetchAgentFeedback } = useAgents()
const { setLoadingState } = useLayoutStore()
const feedbackStore = useFeedbackStore()

/**
 * refs
 */
const currentFeedback = ref()

/**
 * functions
 */
function convertDate(d: string) {
  const sentAtDate: Date = new Date(d)
  const dateWithoutTimezone = new Date(sentAtDate.toISOString().slice(0, -1))
  return new Intl.DateTimeFormat('en-US', { year: 'numeric', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }).format(dateWithoutTimezone)
}
function setCurrentFeedback() {
  if (!route.params.id) {
    currentFeedback.value = feedbackStore.getFeedback[0];
    router.replace(`/agent-feedback/${feedbackStore.getFeedback[0]?.id || ''}`);
  } else {
    currentFeedback.value = feedbackStore.getFeedback.filter((feedback: Feedback) => feedback.id === route.params.id)
  }
}

/**
 * Lifecycle hooks
 */
onBeforeMount(async () => {
  await Promise.allSettled([fetchAgentFeedback({is_acknowledged: null}), fetchAgentFeedback({is_acknowledged: false})])
  setCurrentFeedback()
  setLoadingState(false)
})

/**
 * Watchers
 */
watch(() => route.params, (newVal) => {
  if (newVal != null && newVal.id !== currentFeedback.value) {
    currentFeedback.value = feedbackStore.getFeedback.find((feedback: Feedback) => feedback.id === newVal?.id);
  }
})

onMounted(() => {
  setLoadingState(false)
})

</script>
<template>
  <div class="tw-px-2 md:tw-px-6 tw-pt-6 tw-pb-4 tw-mx-auto tw-max-w-[1536px] tw-flex-grow">
    <header class="tw-flex tw-items-center tw-pb-6 xl:tw-pb-7">
      <h1 class="tw-text-shyftoff-purple tw-text-2xl tw-mr-auto tw-font-black tw-w-full tw-text-center md:tw-text-left">
        Agent Feedback
      </h1>
    </header>
    <div class="tw-space-y-6">
      <v-card v-if="currentFeedback" border="#E8E8EA" class="!tw-p-0 tw-overflow-visible ">
        <v-card-text class="tw-p-6 tw-py-0 tw-pr-0 tw-text-base">
          <div class="tw-grid tw-grid-cols-12 tw-reverse tw-h-full">
            <RouterView :currentFeedback="currentFeedback"></RouterView>
            <div class="tw-col-span-12 -tw-order-1 md:tw-order-2 md:tw-col-span-4 xl:tw-col-span-3 tw-border-l-2 tw-border-solid tw-border-[#EBEBEB] tw-mt-6 md:tw-mt-0">
              <div class="tw-sticky tw-top-0 tw-h-full">
                <div class="tw-relative -tw-left-0.5 tw-top-0 tw-p-6 tw-w-full tw-h-full">
                  <ul>
                    <li v-for="(feedback, index) in feedbackStore.getFeedback" :key="feedback.id" :class="{ 'tw-sticky tw-top-0 tw-bg-white': feedback.id === route.params.id, 'tw-border-b tw-border-solid tw-border-[#EAEAEA]': index !== feedbackStore.getFeedback.length - 1}">
                      <RouterLink :to="`/agent-feedback/${feedback.id}`" class="tw-block xl:tw-text-lg tw-font-semibold tw-text-regular-text tw-py-3"
                        :class="{ 'tw-text-shyftoff-pink tw-py-3 tw-relative before:tw-content-[\'\'] before:tw-absolute before:tw-h-full before:tw-top-0 before:-tw-left-[24px] before:tw-b-l before:tw-b-solid before:tw-w-[2px] before:tw-bg-shyftoff-pink': feedback.id === route.params.id }">
                        <small class="tw-text-sm tw-font-normal tw-text-darker-light-text" :class="{'tw-text-light-text': feedback.is_acknowledged != null}">
                          {{ convertDate(feedback.created_at) }}
                        </small>
                        <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
                          <h5 class="tw-font-extrabold" :class="{ 'tw-text-light-text': feedback.is_acknowledged != null, 'tw-text-shyftoff-pink':  feedback.id === route.params.id}">
                            {{ feedback.campaign.name }}
                          </h5>
                          <i v-if="feedback.is_acknowledged != null" :class="`mdi mdi-check-circle tw-text-lg tw-leading-none tw-text-light-text`"></i>
                        </div>
                      </RouterLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div v-else class="tw-text-center tw-mt-6 tw-mb-8 tw-col-span-12">
        <h5 class="tw-font-extrabold tw-text-lg tw-mb-6 tw-text-darker-light-text">
          No feedback so far.
        </h5>
        <img src="@/assets/images/empty-state--agent-feedback.svg" width="1355" height="349"
          class="tw-block !tw-min-h-[160px] sm:!tw-min-h-[270px] tw-max-w-full tw-relative tw-left-1/2 -tw-translate-x-1/2 tw-object-cover tw-scale-90">
      </div>
    </div>
  </div>
</template>
