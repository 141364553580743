<template>
  <VueApexCharts type="radialBar" height="260" :options="chartOptions" :series="[props.series] ?? [0]" class="tw-self-center lg:tw-self-auto" />
</template>

<script lang="ts" setup>
  import VueApexCharts from 'vue3-apexcharts'
  import { ref } from 'vue'

const props = defineProps(['series'])


const chartOptions = ref({
  colors: ['#5723B4'],
  chart: {
      type: 'radialBar',
      offsetY: 0
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: '#f2f2f2'
        },
        dataLabels: {
          name: {
            show: false,
            fontSize: '16px',
            color: '#5723B4',
            offsetY: 130
          },
          value: {
            offsetY: 9,
            fontFamily: 'Nunito',
            fontSize: '20px',
            fontWeight: 600,
            color: '#5723B4',
            formatter: function (val: any) {
              return val + "%"
            }
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
          shade: 'dark',
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91]
      },
    },
    stroke: {
      dashArray: 4
    },
  }
)
</script>
