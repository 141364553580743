import { ref } from 'vue'
import axios, { isAxiosError } from 'axios'
import type { Notification } from '@/types/notification'
import useHelpers from './useHelpers'

export default function useNotifications() {
  const apiBaseUrl = import.meta.env.VITE_API_URL
  const notification = ref<Notification | {}>({})
  const { errorHelper } = useHelpers()

  async function changeNotificationStatus(payload: { id: number; is_read: boolean; is_received: boolean; }) {
    try {
      const response = await axios.patch(`${apiBaseUrl}/api/users/me/notifications/${payload.id}`, {
          is_received: payload.is_received,
          is_read: payload.is_read
      })
      notification.value = response
      return response
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  return {
    changeNotificationStatus,
    notification
  }

}
