import { defineStore, type StateTree } from 'pinia'
import type { Notification } from '@/types/notification'

export const useNotificationStore = defineStore('notifications', {
  state: () => ({
    notifications: null as Notification[] | null,
    unreadNotifications: [] as Notification[]
  }),
  getters: {
    getNotifications: (state: any) => state.notifications,
    getUnreadNotifications: (state: any) => state.unreadNotifications,
    getUnreadNotificationsCount: (state: any) => {
      return state.unreadNotifications.length
    }
  },
  actions: {
    setNotifications(n: Notification[]) {
      this.notifications = n
    },
    setUnreadNotifications(n: Notification[]) {
      this.unreadNotifications = n
    },
    addNotification(n: Notification) {
      if (this.notifications != null) {
        this.notifications.unshift(n)
      }
      this.unreadNotifications.unshift(n)
    },
    changeNotificationStatus(id: number) {
      if (this.notifications != null) {
        this.notifications = this.notifications.map((notification: Notification) => {
          if (notification.id === id) {
            return { ...notification, read_at: null}
          }
          return notification
        })
      }
    }
  }
})
