<script setup lang="ts">
import useReviewInteraction from '@/composables/useReviewInteraction';
import type { Interaction, InteractionReview } from '@/types/feedback';
import { reactive, ref } from 'vue';
import type { VForm } from 'vuetify/components';


const props = defineProps<{ currentInteraction: Interaction }>()

const formRef = ref<InstanceType<typeof VForm> | null>(null);
const answers = reactive<{ [key: number]: string }>({});
const submmittingForm = ref(false);

const { createInteractionReview } = useReviewInteraction()

const submit = async () => {
    const result = await formRef.value?.validate();

    if (!result?.valid) return;

    try {
        submmittingForm.value = true;

        const formData: InteractionReview = {
            shyftoff_id: props.currentInteraction.shyftoff_id,
            campaign_id: props.currentInteraction.campaign.id,
            interaction_id: props.currentInteraction.id,
            answers: Object.entries(answers).map(([question_id, answer]) => ({
                question_id: Number(question_id),
                answer,
            })),
        };

        await createInteractionReview(formData)
    } catch (error) {
        console.error(error)
    } finally {
        submmittingForm.value = false;
    }
};

</script>

<template>
    <v-card border="#E8E8EA" class="tw-overflow-visible tw-flex-1 tw-flex tw-flex-col tw-p-8 tw-gap-y-8">
        <div class=" tw-flex tw-gap-x-4  tw-items-center">
            <h3 class="heading_text">Interaction Id:</h3>
            <p class="heading_value">{{ currentInteraction.interaction_id }}</p>
        </div>

        <div class=" tw-flex tw-flex-col tw-gap-y-8 tw-gap-x-11 tw-items-start md:tw-flex-row">
            <div class=" tw-flex tw-gap-x-4 tw-items-center">
                <h3 class="heading_text">Campaign:</h3>
                <p class="heading_value">{{ currentInteraction.campaign.name }}</p>
            </div>

            <div class=" tw-flex tw-gap-x-4  tw-items-center">
                <h3 class="heading_text">Reward:</h3>
                <div
                    class="tw-rounded-md tw-border tw-border-dashed tw-border-[#B5D9CC] tw-text-[#249D72] tw-relative tw-w-[70px] tw-py-0.5 tw-text-center">
                    <b class="tw-font-extrabold">
                        {{ currentInteraction.bounty }}
                    </b>
                </div>
            </div>
        </div>

        <div v-if="currentInteraction.instructions_description">
            <h3 class="heading_text">Description:</h3>
            <p class="tw-mt-2 tw-text-base">{{ currentInteraction.instructions_description }}</p>
        </div>

        <v-form ref="formRef" validate-on="submit" @submit.prevent="submit" class="tw-flex tw-flex-col tw-gap-y-6">
            <div class=" tw-flex tw-gap-x-4  tw-items-center">
                <h3 class="heading_text">Observations:</h3>

                <v-tooltip location="bottom center" origin="auto" no-click-animation open-on-click>
                    <template v-slot:activator="{ props }">
                        <i v-bind="props" class="mdi mdi-help-circle tw-text-lg tw-text-[#c4c3c3]" />
                    </template>

                    <p class="tw-leading-[1.4] tw-max-w-[300px]">Questions marked with * are for internal use only and
                        will be hidden
                        from agent feedback.
                    </p>
                </v-tooltip>
            </div>

            <div v-for="(question, index) in currentInteraction.campaign_observation_questions" :key="question.id"
                class="tw-flex tw-flex-col tw-gap-y-3">
                <div class="tw-flex tw-flex-col tw-gap-y-4">
                    <v-label
                        class="tw-text-base tw-font-medium tw-text-[#343434] tw-opacity-100 tw-whitespace-break-spaces">
                        <p>
                            {{ index + 1 }}. {{ question.question_text }} <span v-if="question.is_hidden"
                                class="tw-text-xl tw-font-extrabold tw-text-shyftoff-purple">*</span>
                        </p>
                    </v-label>


                    <v-radio-group v-if="question.answer_type === 1" v-model="answers[question.id]"
                        :rules="[(v: boolean | null) => v !== null && v !== undefined || 'This field is required']">
                        <div class="tw-flex tw-justify-between tw-gap-x-9">
                            <v-radio label="Yes" :true-value="true" value="yes" class="field" />
                            <v-radio label="No" :true-value="false" value="no" class="field" />
                        </div>
                    </v-radio-group>

                    <v-text-field v-else-if="question.answer_type === 2" v-model="answers[question.id]"
                        variant="outlined" :rules="[(v: string) => !!v || 'This field is required']" />
                </div>
            </div>



            <v-btn color="primary" rounded="lg" type="submit" :disabled="submmittingForm" :loading="submmittingForm"
                class="tw-capitalize !tw-h-[49px] tw-py-3 md:tw-max-w-[200px] md:tw-text-base">
                Submit
            </v-btn>
        </v-form>
    </v-card>
</template>

<style scoped lang="scss">
.heading_text {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 800;
}

.heading_value {
    color: #5723b4;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 800;
}

.v-input :deep(.v-field__field) {
    input {
        min-height: 49px;
        padding: 0px;
    }
}

.v-selection-control {
    height: 49px;
    border: 1px solid #DADADA;
    border-radius: 8px;
    flex-direction: row-reverse;

    :deep(.v-label) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-weight: 800;
        opacity: 1;
    }
}
</style>