<script setup lang="ts">
import useUser from '@/composables/useUser'
import { ref, onBeforeMount, watch } from 'vue'
import { useProfileStore } from '@/stores/profile'
import type { ShyftError } from '@/types/error'

const profileStore = useProfileStore()
const { getTimezones, timezoneList, updateSettings } = useUser()


const smsForwarding = ref<boolean>(profileStore.getProfile.agent?.notify_by_sms)
const emailForwarding = ref<boolean>(profileStore.getProfile.agent?.notify_by_email)
const loading = ref(false)
const selectedTimezone = ref(profileStore.getProfile.agent?.timezone)
const showNotification = ref(false)
const settingsChanged = ref(false)
const errorResponse = ref<any>()

onBeforeMount(async () => {
  await getTimezones()
})

watch(() => profileStore.getProfile, (newVal) => {
  if (newVal != null) {
    selectedTimezone.value = newVal.agent?.timezone
    smsForwarding.value = newVal.agent?.notify_by_sms
    emailForwarding.value = newVal.agent?.notify_by_email
  }
})

async function saveSettings() {
  loading.value = true
  try {
    const data = await updateSettings({
      agent: {
        notify_by_email: emailForwarding.value,
        notify_by_sms: smsForwarding.value,
        timezone: selectedTimezone.value
      }
    })
    profileStore.setProfile(data)
    errorResponse.value = null
    loading.value = false
    showNotification.value = true
  } catch (error) {
    loading.value = false
  }
}

</script>
<template>
  <div class="tw-col-span-9 tw-p-6">
    <div>
      <span class="tw-text-gray-500 tw-uppercase tw-text-xs tw-font-extrabold tw-inline-block tw-mb-3">
        Notifications
      </span>
      <div>
        <div>
          <v-switch
            v-model="smsForwarding"
            @update:modelValue="settingsChanged = true"
            label="SMS Forwarding"
            color="#FF66C4"
            close-text="#2B2E41"
            hide-details
            class="tw-inline-block [&>div>div]:!tw-min-h-0 [&>div>div>label]:!tw-h-auto">
          </v-switch>
        </div>
        <div>
          <v-switch
            v-model="emailForwarding"
            @update:modelValue="settingsChanged = true"
            label="Email Forwarding"
            color="#FF66C4"
            close-text="#2B2E41"
            hide-details
            class="tw-inline-block [&>div>div]:!tw-min-h-0 [&>div>div>label]:!tw-h-auto">
          </v-switch>
        </div>
      </div>
    </div>
    <div>
      <span class="tw-text-gray-500 tw-uppercase tw-text-xs tw-font-extrabold tw-inline-block tw-mb-6 tw-mt-6">
        Time preferences
      </span>
      <VSelect
        v-model="selectedTimezone"
        @update:modelValue="settingsChanged = true"
        :items="timezoneList"
        label="Timezone"
        class="tw-flex-grow 2xl:tw-flex-grow-0 !tw-max-w-[320px]"
        variant="outlined"
        density="comfortable"
        rounded="lg">
      </VSelect>
    </div>
    <div class="tw-col-span-12 tw-mt-8">
      <VBtn color="primary" size="large" :loading="loading" flat rounded="lg" :disabled="loading || !settingsChanged" class="tw-px-7 tw-font-semibold tw-text-sm" @click="saveSettings()">
        Update
      </VBtn>
    </div>
  </div>
  <VSnackbar v-model="showNotification" :timeout="6000" :color="errorResponse ? 'red-darken-2' : 'green-darken-2'">
    {{ errorResponse ? 'Some errors occured while updating.' : 'Preferences successfully updated.'}}
  </VSnackbar>
</template>
