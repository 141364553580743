import type { Campaign } from '@/types/campaign'
import { defineStore } from 'pinia'
import type { CampaignApplication } from '@/types/campaign-applications'
import { computed, ref } from 'vue'

export const useCampaignStore = defineStore('campaigns', () => {
  const campaigns = ref<Campaign[]>([])
  const activeCampaigns = ref<Campaign[]>([])
  const campaignCount = ref<number>(0)
  const activeCampaignCount = ref<number>(0)
  const campaignApplications = ref<CampaignApplication[]>([])

  const getCampaigns = computed(() => campaigns.value ?? [])
  const getActiveCampaigns = computed(() => activeCampaigns.value)
  const getCount = computed(() => campaignCount.value)
  const getActiveCount = computed(() => activeCampaignCount.value)
  const getCampaignApplications = computed(() => campaignApplications.value)

  function setCampaigns(n: Campaign[]) {
    campaigns.value = n
  }
  function setActiveCampaigns(n: Campaign[]) {
    const campaignIds = activeCampaigns.value.map((c: Campaign) => c.id)
    const campaigns = n.filter((c: Campaign) => campaignIds.indexOf(c.id) === -1 )
    activeCampaigns.value.push(...campaigns)
  }
  function addActiveCampaign(n: Campaign) {
    activeCampaigns.value.push(n)
    activeCampaignCount.value++
  }
  function setCampaignCount(n: number) {
    campaignCount.value = n
  }
  function setActiveCampaignCount(n: number) {
    activeCampaignCount.value = n
  }
  function setCampaignApplications(n: CampaignApplication[]) {
    campaignApplications.value = n
  }

  return {
    campaigns,
    activeCampaigns,
    campaignCount,
    activeCampaignCount,
    campaignApplications,
    getCampaigns,
    getActiveCampaigns,
    getCount,
    getActiveCount,
    getCampaignApplications,
    setCampaigns,
    setActiveCampaigns,
    addActiveCampaign,
    setCampaignCount,
    setActiveCampaignCount,
    setCampaignApplications
  }
})
