import { ref, toRaw, toRefs } from 'vue'
import axios, { isAxiosError } from 'axios'
import type { Agent } from '@/types/agent'
import { useFeedbackStore } from '@/stores/feedback'
import { usePaymentsStore } from '@/stores/payments'
import useHelpers from './useHelpers'
import { useAgentStore } from '@/stores/agents'

export default function useAgents() {
  const apiBaseUrl = import.meta.env.VITE_API_URL
  const pageNumber = ref(1)
  const itemsPerPage = ref(100)
  const isLastPage = ref(false)
  const { errorHelper } = useHelpers()
  const currentAgent = ref<Agent | null>()
  const initialAgentsList = ref<Agent[]>([])
  const agentsList = ref<Agent[]>([])
  const currentPage = ref(1)
  const numberOfPages = ref(1)

  function itemsToSkip(): number {
    return (currentPage.value - 1) * itemsPerPage.value
  }

  async function fetchAgents(
    { with_count, items_per_page, query }: Record<string, any> = {
      with_count: false,
      items_per_page: 30,
      query: null
    }
  ) {
    try {
      if (items_per_page) itemsPerPage.value = items_per_page
      const { setAgents, setAgentCount } = useAgentStore()
      const { agentList } = toRefs(useAgentStore())
      const response = await axios.get(`${apiBaseUrl}/api/agents`, {
        params: {
          offset: itemsToSkip(),
          limit: items_per_page ?? itemsPerPage.value,
          with_count: with_count ?? false,
          query: query ?? null
        }
      })

      if (currentPage.value === 1) {
        currentAgent.value = response.data?.results?.length ? response.data?.results[0] : null
      }
      if (response.data?.results?.length) setAgents(response.data.results)
      if (with_count) {
        setAgentCount(response.data.count)
        numberOfPages.value = Math.ceil(response.data.count / items_per_page)
      } else {
        ++pageNumber.value
        setAgents(response.data)
        isLastPage.value = response.data.length < itemsPerPage.value
      }
      initialAgentsList.value = JSON.parse(JSON.stringify(toRaw(agentList.value)))
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchAgentRoster({
    offset,
    limit,
    with_count,
    campaign_id,
    reset,
    query,
    sort_field,
    sort_direction,
    agent_status_id = [],
    response_type = 'json'
  }: Record<string, any>) {
    try {
      if (limit) itemsPerPage.value = limit
      const { setAgents, setAgentCount, resetAgents } = useAgentStore()
      const { agentList } = toRefs(useAgentStore())

      const params: Record<string, any> = {
        response_type,
        with_count: with_count ?? false,
        campaign_id: campaign_id,
        query: query ? query : null,
        sort_field: sort_field ? sort_field : null,
        sort_direction: sort_direction ? sort_direction : null
      }

      // Build the agent_status_id query string
      let agentStatusIdQueryString = ''

      if (agent_status_id.length > 0) {
        agentStatusIdQueryString = agent_status_id
          .map((id: number) => `agent_status_id=${id}`)
          .join('&')
      }

      if (response_type !== 'csv') {
        params.offset = offset
        params.limit = limit ?? itemsPerPage.value
      }

      const response = await axios.get(
        `${apiBaseUrl}/api/agents/roster?${agentStatusIdQueryString}`,
        { params }
      )

      if (currentPage.value === 1) {
        currentAgent.value = response.data?.results?.length ? response.data?.results[0] : null
      }

      if (response.data) {
        if (with_count) {
          setAgentCount(response.data.count)
        }

        if (reset) resetAgents()
        if (typeof response.data === 'object') {
          if ('results' in response.data) {
            setAgents(response.data?.results)
          } else {
            setAgents(response.data)
          }
        }
        isLastPage.value = response.data.length < itemsPerPage.value
      }

      initialAgentsList.value = JSON.parse(JSON.stringify(agentList.value))

      return response.data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function searchAgent(query: string) {
    const { setAgents } = useAgentStore()
    try {
      if (query === '' || query === currentAgent.value?.name) {
        setAgents(structuredClone(initialAgentsList.value))
        return
      }
      const response = await axios.get(`${apiBaseUrl}/api/agents?query=${query}`)
      setAgents(response?.data)
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchAgentStats(id: number) {
    try {
      const statsByCampaign = () => axios.get(`${apiBaseUrl}/api/agents/${id}/stats/by-campaign`)
      const dailyStatsByCampaign = () =>
        axios.get(`${apiBaseUrl}/api/agents/${id}/stats/daily-by-campaign`)
      const agentStats = () => axios.get(`${apiBaseUrl}/api/agents/${id}/stats`)
      const dailyStats = () => axios.get(`${apiBaseUrl}/api/agents/${id}/stats/daily`)

      const [statsByCampaignValue, dailyStatsByCampaignValue, agentStatsValue, dailyStatsValue] =
        await Promise.allSettled([
          statsByCampaign(),
          dailyStatsByCampaign(),
          agentStats(),
          dailyStats()
        ])
      return {
        statsByCampaign: statsByCampaignValue,
        dailyStatsByCampaign: dailyStatsByCampaignValue,
        agentStats: agentStatsValue,
        dailyStats: dailyStatsValue
      }
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchAgentFeedback({ is_acknowledged }: { is_acknowledged: boolean | null }) {
    try {
      const feedbackStore = useFeedbackStore()
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/feedback`, {
        params: {
          is_acknowledged: is_acknowledged ?? null
        }
      })

      if (is_acknowledged === false) {
        feedbackStore.setUnacknowledged(data.length)
      } else {
        feedbackStore.setFeedbackList(toRaw(data))
      }
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function retrieveAgentFeedback(id: number | string) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/feedback/${id}`, {
        params: {
          id_: id
        }
      })
      return data
    } catch (error) {
      console.error(error)
      if (axios.isAxiosError(error)) {
        throw new Error(error.message)
      }
    }
  }

  async function updateFeedback({
    id,
    is_acknowledged,
    agent_comment
  }: {
    id: number | string
    is_acknowledged: boolean
    agent_comment: string
  }) {
    try {
      const { data } = await axios.patch(`${apiBaseUrl}/api/users/me/feedback/${id}`, {
        is_acknowledged: is_acknowledged,
        agent_comment: agent_comment
      })
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchAgentPayroll() {
    try {
      const paymentsStore = usePaymentsStore()
      // Calculate start and end dates
      const endDate = new Date()
      const endDateWithoutTimezone = new Date(endDate.toISOString().slice(0, -1))
      const startDate = new Date()
      const startDateWithoutTimezone = new Date(startDate.toISOString().slice(0, -1))
      startDateWithoutTimezone.setDate(endDateWithoutTimezone.getDate() - 14) // Set to 14 days before the current date

      // Format dates to 'YYYY-MM-DD'
      const formattedStartDate = startDateWithoutTimezone.toISOString().split('T')[0]
      const formattedEndDate = endDateWithoutTimezone.toISOString().split('T')[0]

      const { data } = await axios.get(`${apiBaseUrl}/api/users/me/payments`, {
        params: {
          with_count: true,
          start_date: formattedStartDate,
          end_date: formattedEndDate
        }
      })
      paymentsStore.setPayments(toRaw(data.results))
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchAgentCampaigns(id: number) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/agents/${id}/stats/by-campaign`)
      return data
    } catch (error) {
      console.error(error)
      if (axios.isAxiosError(error)) {
        throw new Error(error.message)
      }
    }
  }

  async function updateAgent({ id, payload }: { id: number; payload: any }) {
    try {
      const { data } = await axios.patch(`${apiBaseUrl}/api/agents/${id}`, { ...payload })
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function retrieveAgent(id: number) {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/api/agents/${id}`)
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function createAgentQualificationResult(
    qualification_id: number,
    for_agent_id: number,
    campaign_id: number
  ) {
    const params = new URLSearchParams(
      `qualification_id=${qualification_id}&campaign_id=${campaign_id}&for_agent_id=${for_agent_id}`
    )

    return axios.post(`${apiBaseUrl}/api/qualification-results/assign-qualification?${params}`)
  }

  return {
    initialAgentsList,
    agentsList,
    pageNumber,
    itemsPerPage,
    isLastPage,
    currentAgent,
    currentPage,
    numberOfPages,
    searchAgent,
    fetchAgents,
    fetchAgentStats,
    fetchAgentFeedback,
    retrieveAgentFeedback,
    updateFeedback,
    fetchAgentPayroll,
    fetchAgentCampaigns,
    updateAgent,
    fetchAgentRoster,
    retrieveAgent,
    createAgentQualificationResult
  }
}
