<script setup lang="ts">
import { useNotificationStore } from '@/stores/notifications'
import useNotifications from '@/composables/useNotifications'
import { vOnClickOutside } from '@vueuse/components'
import { computed, ref } from 'vue'
import type { Notification } from '@/types/notification'

const emit = defineEmits(['closeNotificationEmitted'])
const notificationStore = useNotificationStore()

async function closeNotifications(event: Event) {
  if (!(event.target as HTMLElement).classList.contains('notifications') && !(event.target as HTMLElement).classList.contains('listTitle') && !(event.target as HTMLElement)?.classList.contains('listItem')) {
    markAllAsRead()
    skipMarkAllAsRead.value = false
    emit('closeNotificationEmitted')
  }
}

function convertDate(d: string) {
  const sentAtDate = new Date(d)
  const dateWithoutTimezone = new Date(sentAtDate.toISOString().slice(0, -1))
  return new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' }).format(dateWithoutTimezone)
}

import useUser from '@/composables/useUser'
import type { ComputedRef } from 'vue'
const patchNotifications: Function[] = []
const { changeNotificationStatus } = useNotifications()
let unreadNotifications: ComputedRef<Notification[] | []> = computed(() => {
  return notificationStore.getUnreadNotifications
})

const loadingMarkAllAsRead = ref(false)
const skipMarkAllAsRead = ref(false)
async function markAllAsRead() {
  if (unreadNotifications.value.length && !skipMarkAllAsRead.value) {
    loadingMarkAllAsRead.value = true
      unreadNotifications.value.forEach((notification: Notification) => {
      if (notification.id !== undefined && typeof notification.id === 'number') {
        patchNotifications.push(() => {
            return new Promise<void>((resolve) => {
              changeNotificationStatus({ id: (notification.id as number), is_received: true, is_read: true}).then(() => {
                resolve()
              })
            })
          })
        }
    })
    await Promise.all(patchNotifications.map((promiseWrapper) => promiseWrapper()))
    const { getUserNotifications } = useUser()
    await Promise.all([getUserNotifications({ type: 'all', options: { limit: 5 * currentPage.value } }), getUserNotifications({ type: 'unread', options: { is_read: false} })])
    loadingMarkAllAsRead.value = false
  }
}

const popoverMenu = ref([{ title: 'Mark as unread' }])
async function markAsUnread(id: number | undefined) {
  if (typeof id === 'number') {
    await changeNotificationStatus({ id: id, is_received: true, is_read: false })
    let currentNotification = notificationStore.getNotifications.find((notification: Notification) => notification.id === id)
    let unreadNotifications = notificationStore.getUnreadNotifications
    notificationStore.setUnreadNotifications(unreadNotifications.concat([currentNotification]))
    notificationStore.changeNotificationStatus(id)
    skipMarkAllAsRead.value = true
  }
}

const { getUserNotifications } = useUser()
const currentPage = ref(1)
const loadingNotifications = ref(false)
async function loadMoreNotifications() {
  loadingNotifications.value = true
  currentPage.value++
  await getUserNotifications({ type: 'all', options: { limit: 5 * currentPage.value } })
  loadingNotifications.value = false
}

</script>

<template>
  <v-card v-on-click-outside="closeNotifications" class="tw-w-[320px] tw-h-screen tw-bg-white !tw-fixed tw-left-0 lg:tw-left-[70px] xl:tw-left-[250px] tw-top-0 !tw-pt-0 !tw-p-0 !lg:tw-z-5 !tw-z-10 !tw-border-none !tw-rounded-l-none">
    <v-card-title class="tw-py-4 tw-border-b tw-border-solid tw-border-b-[#E6E6E6] tw-shadow-sm">
      <div class="tw-flex tw-justify-between tw-items-center">
        <p class="tw-font-extrabold tw-text-base tw-text-regular-text">Notifications</p>
        <VBtn @click="closeNotifications" color="#DADADA" flat rounded="circle" variant="outlined" width="30px" height="30px" min-width="0" class="tw-p-0">
          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0672 12.1828C13.1253 12.2409 13.1713 12.3098 13.2028 12.3857C13.2342 12.4615 13.2504 12.5429 13.2504 12.625C13.2504 12.7071 13.2342 12.7884 13.2028 12.8643C13.1713 12.9402 13.1253 13.0091 13.0672 13.0672C13.0091 13.1252 12.9402 13.1713 12.8643 13.2027C12.7885 13.2342 12.7071 13.2503 12.625 13.2503C12.5429 13.2503 12.4616 13.2342 12.3857 13.2027C12.3098 13.1713 12.2409 13.1252 12.1828 13.0672L7.00001 7.88358L1.8172 13.0672C1.69992 13.1844 1.54086 13.2503 1.37501 13.2503C1.20916 13.2503 1.0501 13.1844 0.932824 13.0672C0.815549 12.9499 0.749664 12.7908 0.749664 12.625C0.749664 12.4591 0.815549 12.3001 0.932824 12.1828L6.11642 6.99998L0.932824 1.81717C0.815549 1.69989 0.749664 1.54083 0.749664 1.37498C0.749664 1.20913 0.815549 1.05007 0.932824 0.932794C1.0501 0.815518 1.20916 0.749634 1.37501 0.749634C1.54086 0.749634 1.69992 0.815518 1.8172 0.932794L7.00001 6.11639L12.1828 0.932794C12.3001 0.815518 12.4592 0.749634 12.625 0.749634C12.7909 0.749634 12.9499 0.815518 13.0672 0.932794C13.1845 1.05007 13.2504 1.20913 13.2504 1.37498C13.2504 1.54083 13.1845 1.69989 13.0672 1.81717L7.88361 6.99998L13.0672 12.1828Z" />
          </svg>
        </VBtn>
      </div>
    </v-card-title>
    <v-card-text class="!tw-p-0 !tw-text-base">
      <ul v-if="notificationStore.notifications?.length" class="tw-p-0 tw-h-[calc(100vh_-_69px)] tw-overflow-y-auto tw-text-[0px]">
        <li v-for="(notification, index) in notificationStore.notifications" :key="notification.id" class="tw-pt-3 tw-relative" :class="{'tw-bg-[#EEECFF]': notification.read_at === null}">
          <time class="tw-text-xs tw-text-gray-500 tw-relative before:tw-content-[''] before:tw-inline-block before:tw-w-2 before:tw-h-2 before:tw-rounded-full before:tw-absolute before:-tw-left-5 before:tw-top-1/2 before:-tw-translate-y-1/2 tw-ml-8 before:tw-bg-[#D1D1D1]" :class="{'before:!tw-bg-shyftoff-pink tw-text-[#827EA2]': notification.read_at === null}">
            {{ notification.sent_at != null ? convertDate(notification.sent_at) : notification.created_at ? convertDate(notification.created_at) : '' }}
          </time>
          <p class="tw-text-sm tw-pb-3 tw-border-b tw-border-solid tw-border-b-[#EAEAEA] tw-ml-8 tw-pr-10 tw-text-regular-text" :class="{'!tw-border-none': notificationStore.notifications != null && index === notificationStore.notifications.length - 1, '!tw-border-l-[#CFC3E7] !tw-text-[#251F46] !tw-border-b-[#E4DAF5]': notification.read_at === null}" v-html="notification.message">
          </p>
          <v-menu v-if="notification.read_at !== null">
            <template v-slot:activator="{ props }">
              <v-btn icon="mdi-dots-vertical" class="tw-absolute tw-right-2 tw-top-3 tw-text-gray-500/50 hover:tw-text-shyftoff-purple" flat width="24" height="24" v-bind="props"></v-btn>
            </template>
            <v-list density="compact">
              <v-list-item v-for="(item, i) in popoverMenu" :key="i" @click.prevent="markAsUnread(notification.id)" class="listItem tw-cursor-pointer">
                <v-list-item-title class="listTitle tw-text-sm tw-text-regular-text">
                    {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </li>
        <li>
          <VBtn v-if="notificationStore.getNotifications?.length >= 5" @click="loadMoreNotifications" :loading="loadingNotifications" color="#D7D7D7" flat variant="outlined" rounded="0" width="100%" height="48px" class="tw-border-l-0 tw-border-r-0 tw-border-b tw-border-t !tw-border-b-[#EAEAEA] !tw-border-t-[#EAEAEA] tw-border-solid tw-text-gray-500 tw-text-sm tw-w-full tw-h-12 tw-font-semibold hover:tw-text-regular-text hover:!tw-border-b-[#D7D7D7] hover:!tw-border-t-[#D7D7D7] [&>span]:tw-text-gray-500 [&>span]:hover:tw-text-shyftoff-purple [&>span]:tw-normal-case [&>span]:tw-tracking-normal hover:!tw-bg-transparent [&>span]:hover:!tw-bg-transparent">
            Load more
          </VBtn>
        </li>
      </ul>
      <div v-else>
        <img src="@/assets/images/empty-state--notifications.svg" alt="">
        <h3 class="tw-px-3 tw-text-center">No notifications yet.</h3>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
  .v-card {
    :deep(a) {
      @apply tw-text-shyftoff-purple tw-font-bold hover:tw-underline;
    }
  }
  .v-btn {
    @apply tw-grid-cols-[auto_1fr_auto] #{!important};
    ::v-deep(.v-btn__content) {
      @apply tw-w-full;
      svg {
        @apply tw-fill-[#AEAEAE];
      }
    }
    &:hover {
      @apply tw-border-shyftoff-purple/20 tw-bg-[#f3f2fb]/50;
      ::v-deep(.v-btn__content) {
        span {
          @apply tw-text-shyftoff-purple;
        }
        svg {
          @apply tw-fill-shyftoff-purple/70;
        }
      }
    }
  }

</style>
